import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();

// Add performance optimizations
if ('serviceWorker' in navigator) {
  window.addEventListener('load', () => {
    navigator.serviceWorker.register('/service-worker.js').catch(error => {
      console.log('Service Worker registration failed:', error);
    });
  });
}

// Add smooth scrolling
document.documentElement.style.scrollBehavior = 'smooth';

// Optimise images loading
const images = document.querySelectorAll('img');
if ('loading' in HTMLImageElement.prototype) {
  images.forEach(img => {
    if (img.hasAttribute('data-src')) {
      img.setAttribute('loading', 'lazy');
      img.setAttribute('src', img.getAttribute('data-src'));
    }
  });
}
