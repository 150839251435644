import React from 'react';
import './TermsAndConditions.css';

const TermsAndConditions = () => {
  return (
    <div className="terms-and-conditions">
      <div className="container">
        <h1>Terms and Conditions</h1>
        <div className="content">
          <section>
            <h2>1. Introduction</h2>
            <p>These terms and conditions govern your use of B E Services Ltd's website and services. By using our website and services, you accept these terms and conditions in full.</p>
          </section>

          <section>
            <h2>2. Company Information</h2>
            <p>B E Services Ltd is a company registered in England and Wales with company number 07100422. Our registered office is at 173 Brunswick Road, Ipswich, Suffolk IP4 4DB.</p>
          </section>

          <section>
            <h2>3. Services</h2>
            <p>We provide electrical and air conditioning services throughout Ipswich and Suffolk. All our work is carried out by qualified professionals who are NICEIC approved and F-Gas registered.</p>
          </section>

          <section>
            <h2>4. Quotes and Pricing</h2>
            <p>All quotes provided are valid for 30 days from the date of issue. Prices are subject to change without notice. All prices include VAT unless otherwise stated.</p>
          </section>

          <section>
            <h2>5. Payment Terms</h2>
            <p>Payment terms are agreed upon before work commences. We accept various payment methods including cash, bank transfer, and card payments. Payment is due upon completion of work unless otherwise agreed.</p>
          </section>

          <section>
            <h2>6. Warranty</h2>
            <p>We provide warranties on our workmanship and products as per manufacturer specifications. Warranty details will be provided with your quote and invoice.</p>
          </section>

          <section>
            <h2>7. Health and Safety</h2>
            <p>We adhere to all relevant health and safety regulations and guidelines. Our team members are trained in health and safety procedures and wear appropriate protective equipment.</p>
          </section>

          <section>
            <h2>8. Privacy Policy</h2>
            <p>We collect and process personal data in accordance with our privacy policy and data protection regulations. By using our services, you consent to such processing.</p>
          </section>

          <section>
            <h2>9. Limitation of Liability</h2>
            <p>We are not liable for any indirect or consequential loss or damage arising from our services. Our liability is limited to the cost of the work carried out.</p>
          </section>

          <section>
            <h2>10. Changes to Terms</h2>
            <p>We reserve the right to modify these terms and conditions at any time. Changes will be effective immediately upon posting to our website.</p>
          </section>

          <section>
            <h2>11. Contact Information</h2>
            <p>For any questions regarding these terms and conditions, please contact us:</p>
            <p>Phone: 01473 728352</p>
            <p>Email: info@bre-services.com</p>
            <p>Address: 173 Brunswick Road, Ipswich, Suffolk IP4 4DB</p>
          </section>

          <section>
            <h2>12. Governing Law</h2>
            <p>These terms and conditions are governed by and construed in accordance with English law. Any disputes shall be subject to the exclusive jurisdiction of the English courts.</p>
          </section>
        </div>
      </div>
    </div>
  );
};

export default TermsAndConditions; 