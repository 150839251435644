export const sendQuoteEmail = async (clientDetails, quoteDetails) => {
  try {
    // Validate input
    if (!clientDetails || !clientDetails.email || !quoteDetails) {
      console.error('Missing required fields:', { clientDetails, quoteDetails });
      throw new Error('Missing required fields: clientDetails and quoteDetails are required');
    }

    console.log('Sending quote email to:', clientDetails.email);
    console.log('Quote details:', {
      type: quoteDetails.type,
      systemType: quoteDetails.systemType,
      totalUnits: quoteDetails.totalUnits,
      totalCost: quoteDetails.totalCost
    });

    const response = await fetch('/.netlify/functions/send-quote', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        clientDetails,
        quoteDetails,
        companyEmail: process.env.BUSINESS_EMAIL || 'info@bre-services.com'
      }),
    });

    // First try to parse the response as JSON
    let data;
    const contentType = response.headers.get('content-type');
    if (contentType && contentType.includes('application/json')) {
      data = await response.json();
    } else {
      // If not JSON, get the text and log it
      const text = await response.text();
      console.error('Received non-JSON response:', text);
      throw new Error('Server returned an invalid response format');
    }

    if (!response.ok) {
      console.error('Failed to send quote email:', data);
      throw new Error(data.message || 'Failed to send quote email');
    }

    console.log('Quote email sent successfully:', data);
    return data;
  } catch (error) {
    console.error('Error sending quote email:', error);
    console.error('Error details:', {
      message: error.message,
      stack: error.stack,
      clientEmail: clientDetails?.email,
      quoteType: quoteDetails?.type,
      clientDetails: {
        name: clientDetails?.name,
        email: clientDetails?.email,
        phone: clientDetails?.phone
      },
      quoteDetails: {
        type: quoteDetails?.type,
        totalCost: quoteDetails?.totalCost,
        totalUnits: quoteDetails?.totalUnits
      }
    });
    throw error;
  }
}; 