import React, { useState } from 'react';
import { MessageCircle } from 'lucide-react';
import './Contact.css';

const Contact = () => {
  const [formData, setFormData] = useState({
    firstName: '',
    lastName: '',
    email: '',
    phone: '',
    location: '',
    serviceRequired: '',
    message: ''
  });

  const services = [
    'Residential Electrical Services',
    'Commercial Electrical Services',
    'Air Conditioning Installation',
    'Air Conditioning Maintenance',
    'Emergency Call-out',
    'EV Charging Points',
    'Victron Energy Solutions'
  ];

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData(prevState => ({
      ...prevState,
      [name]: value
    }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      console.log('Submitting form:', formData);

      const response = await fetch('/.netlify/functions/send-contact', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(formData),
      });

      const data = await response.json();

      if (!response.ok) {
        console.error('Error response from server:', data);
        throw new Error(data.message || 'Failed to submit form');
      }

      console.log('Form submitted successfully:', data);
      
      // Clear form
      setFormData({
        firstName: '',
        lastName: '',
        email: '',
        phone: '',
        location: '',
        serviceRequired: '',
        message: ''
      });

      // Show success message
      alert('Thank you for your message. We will get back to you shortly.');
    } catch (error) {
      console.error('Error submitting form:', error);
      console.error('Error details:', {
        message: error.message,
        stack: error.stack,
        formData: {
          firstName: formData.firstName,
          lastName: formData.lastName,
          email: formData.email,
          phone: formData.phone,
          location: formData.location,
          serviceRequired: formData.serviceRequired
        }
      });
      alert(`There was an error submitting your message: ${error.message}. Please try again or contact us at info@bre-services.com`);
    }
  };

  const whatsappNumber = '07798683723'.replace(/\s+/g, '');
  const whatsappMessage = encodeURIComponent('Hi, I would like to enquire about your electrical/air conditioning services.');
  const whatsappLink = `https://wa.me/44${whatsappNumber}?text=${whatsappMessage}`;

  return (
    <div className="container">
      <main className="main">
        <div className="section-header">
          <h2>Contact Us</h2>
          <p>Have questions about our electrical or air conditioning services in Suffolk? Need an emergency electrician in Ipswich? Get in touch with our team and we'll be happy to help.</p>
        </div>

        <div className="contact-cards">
          <div className="contact-card">
            <h3>Phone</h3>
            <a href="tel:01473728352" className="phone-number">01473 728352</a>
            <div className="emergency">
              <p>24/7 Emergency Electrical</p>
              <p>Service Available</p>
              <p>Throughout Suffolk</p>
            </div>
          </div>

          <div className="contact-card">
            <h3>Email</h3>
            <div className="email">info@bre-services.com</div>
            <div className="card-details">
              <p>We'll respond to your</p>
              <p>inquiry as soon as possible</p>
            </div>
          </div>

          <div className="contact-card">
            <h3>Address</h3>
            <div className="address">
              <p>Brunswick Electrical</p>
              <p>Services Ltd</p>
              <p className="mt">173 Brunswick Road</p>
              <p>Ipswich, Suffolk IP4 4DB</p>
              <p className="mt">Company No: 07100422</p>
              <p>VAT No: 997 005 100</p>
            </div>
          </div>

          <div className="contact-card">
            <h3>Business Hours</h3>
            <div className="hours">
              <p><span className="bold">Monday - Friday:</span> 8:00 AM - 5:00 PM</p>
              <p className="mt"><span className="bold">Saturday:</span> By appointment</p>
              <p className="mt"><span className="bold">Sunday:</span> Closed</p>
              <p className="mt emergency">(Emergency services available)</p>
            </div>
          </div>
        </div>

        <div className="form-section">
          <h2>Send Us a Message</h2>
          <p>Fill out the form below and we'll get back to you as soon as possible about your electrical or air conditioning needs in Suffolk.</p>

          <form onSubmit={handleSubmit}>
            <div className="form-grid">
              <div className="form-group">
                <label htmlFor="firstName">First name</label>
                <input
                  type="text"
                  id="firstName"
                  name="firstName"
                  value={formData.firstName}
                  onChange={handleChange}
                  required
                />
              </div>

              <div className="form-group">
                <label htmlFor="lastName">Last name</label>
                <input
                  type="text"
                  id="lastName"
                  name="lastName"
                  value={formData.lastName}
                  onChange={handleChange}
                  required
                />
              </div>

              <div className="form-group">
                <label htmlFor="email">Email</label>
                <input
                  type="email"
                  id="email"
                  name="email"
                  value={formData.email}
                  onChange={handleChange}
                  required
                />
              </div>

              <div className="form-group">
                <label htmlFor="phone">Phone</label>
                <input
                  type="tel"
                  id="phone"
                  name="phone"
                  value={formData.phone}
                  onChange={handleChange}
                  required
                />
              </div>

              <div className="form-group">
                <label htmlFor="location">Your Location</label>
                <input
                  type="text"
                  id="location"
                  name="location"
                  value={formData.location}
                  onChange={handleChange}
                  required
                />
              </div>

              <div className="form-group">
                <label htmlFor="serviceRequired">Service Required</label>
                <select
                  id="serviceRequired"
                  name="serviceRequired"
                  value={formData.serviceRequired}
                  onChange={handleChange}
                  required
                >
                  <option value="">Select a service</option>
                  {services.map((service) => (
                    <option key={service} value={service}>
                      {service}
                    </option>
                  ))}
                </select>
              </div>

              <div className="form-group full-width">
                <label htmlFor="message">Message</label>
                <textarea
                  id="message"
                  name="message"
                  value={formData.message}
                  onChange={handleChange}
                  rows={6}
                  placeholder="Please provide details about your electrical or air conditioning requirements in Suffolk..."
                  required
                />
              </div>
            </div>

            <button type="submit" className="submit-button">
              Submit
            </button>
          </form>
        </div>

        <div className="areas-section">
          <h2>Areas We Serve in Suffolk</h2>
          <div className="areas-grid">
            <div>Ipswich</div>
            <div>Woodbridge</div>
            <div>Felixstowe</div>
            <div>Stowmarket</div>
            <div>Bury St Edmunds</div>
            <div>Surrounding Areas</div>
          </div>
        </div>

        <div className="whatsapp-container">
          <a href={whatsappLink} className="whatsapp-button" target="_blank" rel="noopener noreferrer">
            <MessageCircle size={20} />
            <span>Chat on WhatsApp</span>
          </a>
        </div>
      </main>
    </div>
  );
};

export default Contact; 