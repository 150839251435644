import React from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import { HelmetProvider } from 'react-helmet-async';
import Home from './components/Home';
import ServicesOverview from './components/ServicesOverview';
import AboutUs from './components/AboutUs';
import Contact from './components/Contact';
import Header from './components/Header';
import Footer from './components/Footer';
import EVQuoteCalculator from './components/EVQuoteCalculator';
import AirConQuoteCalculator from './components/AirConQuoteCalculator';
import FloatingButtons from './components/FloatingButtons';
import ErrorBoundary from './components/ErrorBoundary';
import NotFound from './components/NotFound';
import TermsAndConditions from './components/TermsAndConditions';
import LocationLandingPage from './components/LocationLandingPage';
import { locations } from './data/locations';
import './App.css';

// Placeholder components for service pages
const ResidentialServices = () => (
  <div className="page-container">
    <h1>Residential Electrical Services</h1>
    <p>Complete electrical solutions for your home in Suffolk</p>
  </div>
);

const AirConditioning = () => (
  <div className="page-container">
    <h1>Air Conditioning Services</h1>
    <p>Expert air conditioning installation and maintenance in Ipswich</p>
  </div>
);

function App() {
  return (
    <HelmetProvider>
      <ErrorBoundary>
        <Router>
          <div className="flex flex-col min-h-screen">
            <Header />
            <main className="flex-grow">
              <Routes>
                <Route path="/" element={<Home />} />
                <Route path="/services" element={<ServicesOverview />} />
                <Route path="/services/residential" element={<ResidentialServices />} />
                <Route path="/services/air-conditioning" element={<AirConditioning />} />
                <Route path="/about" element={<AboutUs />} />
                <Route path="/contact" element={<Contact />} />
                <Route path="/ev-quote" element={<EVQuoteCalculator />} />
                <Route path="/air-con-quote" element={<AirConQuoteCalculator />} />
                <Route path="/terms-and-conditions" element={<TermsAndConditions />} />
                {/* Location-specific routes */}
                {Object.entries(locations).map(([key, location]) => (
                  <Route
                    key={key}
                    path={`/locations/${key}`}
                    element={
                      <LocationLandingPage
                        location={location.name}
                        services={location.services}
                        testimonials={location.testimonials}
                        coordinates={location.coordinates}
                      />
                    }
                  />
                ))}
                <Route path="*" element={<NotFound />} />
              </Routes>
            </main>
            <FloatingButtons />
            <Footer />
          </div>
        </Router>
      </ErrorBoundary>
    </HelmetProvider>
  );
}

export default App;
