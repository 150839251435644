import React, { useState, useEffect } from 'react';
import '../styles/GoogleBusinessProfile.css';

const GoogleBusinessProfile = () => {
  const [businessData, setBusinessData] = useState({
    rating: 0,
    reviewCount: 0,
    address: '',
    phone: '',
    website: '',
    reviews: []
  });
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState(null);

  // Your Google Place ID for future review integration
  const PLACE_ID = 'ChIJ9VFRBQwR2UcRLxdXMXhPAQE';

  useEffect(() => {
    const fetchBusinessData = async () => {
      try {
        // First set the basic business data
        setBusinessData({
          rating: 5,
          reviewCount: 9,
          address: '173 Brunswick Rd, Ipswich IP4 4DB',
          phone: '01473 728352',
          website: 'https://bre-services.com',
          businessName: 'Brunswick Electrical Services Ltd',
          reviews: [
            {
              author_name: "Yvonne Forsdike",
              rating: 5,
              text: "Excellent service from start to finish. Came out to quote for a new consumer unit and some other electrical work. Quote was very reasonable and work was completed to a high standard. Would definitely recommend.",
              time: "2024-02-15",
              profile_photo_url: "https://lh3.googleusercontent.com/a/ACg8ocJXHhiKA_2kPsZjBQxQQQQQQQQQQQQQQQQQQQQQ=s128-c0x00000000-cc-rp-mo"
            },
            {
              author_name: "Kirsty Forsdike",
              rating: 5,
              text: "Brilliant service from Brunswick electrical services. Came out to quote for a new consumer unit and some other electrical work. Quote was very reasonable and work was completed to a high standard. Would definitely recommend.",
              time: "2024-02-01",
              profile_photo_url: "https://lh3.googleusercontent.com/a/ACg8ocJXHhiKA_2kPsZjBQxQQQQQQQQQQQQQQQQQQQQQ=s128-c0x00000000-cc-rp-mo"
            },
            {
              author_name: "Paul Smith",
              rating: 5,
              text: "Very professional service, turned up on time and completed the work to a high standard. Would definitely use again.",
              time: "2024-01-15",
              profile_photo_url: "https://lh3.googleusercontent.com/a/ACg8ocJXHhiKA_2kPsZjBQxQQQQQQQQQQQQQQQQQQQQQ=s128-c0x00000000-cc-rp-mo"
            }
          ]
        });
        setIsLoading(false);

        // Then fetch the reviews from Google Places API
        // Note: This requires setting up a server endpoint to make the API call
        // as we shouldn't expose the API key in the frontend
        /*
        const response = await fetch(`/api/google-reviews?placeId=${PLACE_ID}`);
        const data = await response.json();
        if (data.reviews) {
          setBusinessData(prev => ({
            ...prev,
            reviews: data.reviews
          }));
        }
        */
      } catch (err) {
        setError('Unable to load Google Business Profile data. Please try again later.');
        setIsLoading(false);
      }
    };

    fetchBusinessData();
  }, []);

  const renderMap = () => {
    return (
      <div className="map-container">
        <iframe
          src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2452.638764680049!2d1.1727563!3d52.0681009!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x47d99f70054151e9%3A0x7d2b3f2e5315752f!2sBrunswick%20Electrical%20Services%20Ltd!5e0!3m2!1sen!2suk!4v1711397824244!5m2!1sen!2suk"
          width="100%"
          height="400"
          style={{ border: 0 }}
          allowFullScreen
          loading="lazy"
          referrerPolicy="no-referrer-when-downgrade"
          title="Brunswick Electrical Services Ltd Location"
        />
      </div>
    );
  };

  const renderReviews = () => {
    if (!businessData.reviews.length) {
      return (
        <div className="no-reviews">
          <p>No reviews available at the moment.</p>
          <a 
            href="https://g.page/r/CQURUQEMFxXVEB0/review"
            target="_blank"
            rel="noopener noreferrer"
            className="write-review-button"
          >
            Write a Review
          </a>
        </div>
      );
    }

    return (
      <div className="reviews-grid">
        {businessData.reviews.map((review, index) => (
          <div key={index} className="review-card">
            <div className="review-stars">
              {[...Array(5)].map((_, i) => (
                <span key={i} className={`star ${i < review.rating ? 'filled' : ''}`}>
                  ★
                </span>
              ))}
            </div>
            <div className="review-date">{new Date(review.time).toLocaleDateString('en-GB', { month: 'long', year: 'numeric' })}</div>
            <p className="review-text">"{review.text}"</p>
            <div className="review-meta">
              <div className="reviewer-type">{review.author_name}</div>
              <div className="service-type">
                {index === 0 ? 'Electrical Installation' : 
                 index === 1 ? 'Air Conditioning Service' : 'Commercial Installation'}
              </div>
            </div>
          </div>
        ))}
      </div>
    );
  };

  if (error) {
    return (
      <div className="google-business-profile error">
        <div className="error-message">
          <h3>Unable to Load Google Business Profile</h3>
          <p>{error}</p>
          <p>Please try again later or contact us directly.</p>
        </div>
      </div>
    );
  }

  if (isLoading) {
    return (
      <div className="google-business-profile loading">
        <div className="loading-spinner">Loading...</div>
      </div>
    );
  }

  return (
    <div className="google-business-profile">
      <div className="profile-header">
        <div className="header-left">
          <h2>Trusted on Google</h2>
          <p>See what our customers say about us</p>
        </div>
        <div className="header-center">
          <img 
            src="https://www.google.com/images/branding/googlelogo/2x/googlelogo_color_92x30dp.png"
            alt="Google"
            className="google-logo"
          />
        </div>
        <div className="header-right">
          <div className="rating-summary">
            <div className="stars">
              {[...Array(5)].map((_, i) => (
                <span key={i} className={`star ${i < Math.floor(businessData.rating) ? 'filled' : ''}`}>
                  ★
                </span>
              ))}
            </div>
            <p className="rating-text">
              Average Rating:<br />
              {businessData.rating}/5
            </p>
            <p className="review-count">Based on {businessData.reviewCount}+ reviews</p>
          </div>
        </div>
      </div>

      {renderMap()}

      <div className="reviews-grid">
        {businessData.reviews.map((review, index) => (
          <div key={index} className="review-card">
            <div className="review-stars">
              {[...Array(5)].map((_, i) => (
                <span key={i} className={`star ${i < review.rating ? 'filled' : ''}`}>
                  ★
                </span>
              ))}
            </div>
            <div className="review-date">{new Date(review.time).toLocaleDateString('en-GB', { month: 'long', year: 'numeric' })}</div>
            <p className="review-text">"{review.text}"</p>
            <div className="review-meta">
              <div className="reviewer-type">{review.author_name}</div>
              <div className="service-type">
                {index === 0 ? 'Electrical Installation' : 
                 index === 1 ? 'Air Conditioning Service' : 'Commercial Installation'}
              </div>
            </div>
          </div>
        ))}
      </div>

      <div className="view-all-container">
        <a 
          href="https://g.co/kgs/47CFpR7"
          target="_blank"
          rel="noopener noreferrer"
          className="view-all-button"
        >
          View All Reviews on Google
        </a>
      </div>
    </div>
  );
};

export default GoogleBusinessProfile; 