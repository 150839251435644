import React from 'react';
import { Link } from 'react-router-dom';
import './Footer.css';

const Footer = () => {
  return (
    <footer className="footer">
      <div className="footer-container">
        <div className="footer-content">
          <div className="footer-section">
            <h3>B E Services Ltd</h3>
            <p>173 Brunswick Road</p>
            <p>Ipswich, Suffolk IP4 4DB</p>
            <p>Company No: 07100422</p>
            <p>VAT No: 997 005 100</p>
          </div>
          <div className="footer-section">
            <h3>Contact</h3>
            <p>Phone: 01473 728352</p>
            <p>Email: info@bre-services.com</p>
          </div>
          <div className="footer-section">
            <h3>Accreditations</h3>
            <p>NICEIC Approved</p>
            <p>F-Gas Registered</p>
          </div>
          <div className="footer-section">
            <h3>Legal</h3>
            <p><Link to="/terms-and-conditions">Terms and Conditions</Link></p>
          </div>
        </div>
        <div className="footer-bottom">
          <p>&copy; {new Date().getFullYear()} B E Services Ltd. All rights reserved.</p>
        </div>
      </div>
    </footer>
  );
};

export default Footer; 