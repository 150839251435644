import React, { useState, useEffect } from 'react';
import { sendQuoteEmail } from '../services/emailService';
import './EVQuoteCalculator.css';
import SEO from './SEO';

const EVQuoteCalculator = () => {
  const [step, setStep] = useState(1);
  const [quoteData, setQuoteData] = useState({
    chargerType: '',
    cableLength: 'under10',
    propertyType: '',
    ownership: 'owned',
    hasParking: true,
    wifiConnection: true,
    consumerUnit: 'unknown',
    consumerUnitPhoto: null,
    installationHeight: 'standard',
    additionalWork: [],
    // Customer details
    firstName: '',
    lastName: '',
    email: '',
    phone: '',
    address: '',
    postcode: ''
  });
  const [quote, setQuote] = useState(null);

  // Scroll to top when component mounts
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  // Scroll to top when step changes
  useEffect(() => {
    window.scrollTo({ top: 0, behavior: 'smooth' });
  }, [step]);

  const chargerTypes = [
    {
      id: 'zappi-v2',
      name: 'Zappi v2',
      type: 'tethered',
      basePrice: 874.17, // £1049 inc VAT
      features: [
        '7.4kW fast charging',
        'Solar/wind power compatible',
        'Built-in earthing protection',
        'Smart charging modes',
        'OLEV/OZEV approved',
        '6.5m charging cable included',
        'Wi-Fi + Bluetooth connectivity',
        'Compatible with Octopus Intelligent tariff'
      ]
    },
    {
      id: 'project-ev-eva',
      name: 'Project EV EVA-07S-SE',
      type: 'untethered',
      basePrice: 620.83, // £745 inc VAT
      features: [
        '7.3kW charging power',
        'Type 2 socket',
        'Smart app control',
        'Remote charging schedule',
        'IP65 rated weather protection',
        'Auto power balancing',
        'Compatible with Octopus Intelligent tariff'
      ]
    },
    {
      id: 'ohme-epod',
      name: 'Ohme ePod',
      type: 'untethered',
      basePrice: 749.17, // £899 inc VAT
      features: [
        '7.4kW charging power',
        'Type 2 socket',
        'Smart charging capability',
        'Weather resistant (IP54)',
        'No charging cable included',
        '3G/4G connectivity',
        'Most compact design',
        'Compatible with Octopus Intelligent tariff'
      ]
    },
    {
      id: 'bg-syncev',
      name: 'BG SyncEV Wall Charger',
      type: 'tethered',
      basePrice: 704.65, // £845.58 inc VAT
      features: [
        '7.4kW dynamic charging',
        'Wi-Fi & LAN connectivity',
        '7.5m Type 2 cable',
        'Integrated charging gun holster',
        'Smart OCPP 1.6J compatible',
        'No earth rod required',
        'Compatible with Octopus Intelligent tariff'
      ]
    }
  ];

  const cableLengthOptions = [
    {
      id: 'under10',
      label: 'Under 10 meters (Standard Installation)',
      value: 'under10',
      meters: 0
    },
    {
      id: '10to20',
      label: '10-20 meters (Additional charges apply)',
      value: '10to20',
      meters: 15
    },
    {
      id: 'over20',
      label: 'Over 20 meters (Additional charges apply)',
      value: 'over20',
      meters: 25
    }
  ];

  const propertyTypes = [
    'Detached House',
    'Semi-detached House',
    'Terraced House',
    'Flat/Apartment',
    'Other'
  ];

  const additionalOptions = [
    { id: 'earthing', name: 'Additional Earthing Required', price: 120 },
    { id: 'consumer-upgrade', name: 'Consumer Unit Upgrade', price: 599 },
    { id: 'groundworks', name: 'Groundworks Required', price: 250 },
    { id: 'three-phase', name: 'Three-Phase Connection (DNO charges not included)', price: 300 }
  ];

  const installationCosts = {
    standardCosts: 82.50, // £99 inc VAT - added at quote stage
    installationFee: 0,
    cablePerMeter: 8.25
  };

  const evQuoteSchema = {
    "@context": "https://schema.org",
    "@type": "Service",
    "name": "EV Charging Point Installation Quote Calculator",
    "provider": {
      "@type": "LocalBusiness",
      "name": "B E Services Ltd",
      "image": "https://www.be-services.com/images/shop-front.jpg",
      "address": {
        "@type": "PostalAddress",
        "streetAddress": "173 Brunswick Road",
        "addressLocality": "Ipswich",
        "addressRegion": "Suffolk",
        "postalCode": "IP4 4DB",
        "addressCountry": "GB"
      }
    },
    "description": "Get an instant quote for professional EV charging point installation in Ipswich and Suffolk. NICEIC approved contractor, OZEV approved installer.",
    "areaServed": {
      "@type": "GeoCircle",
      "geoMidpoint": {
        "@type": "GeoCoordinates",
        "latitude": "52.0594",
        "longitude": "1.1556"
      },
      "geoRadius": "30000"
    },
    "hasOfferCatalog": {
      "@type": "OfferCatalog",
      "name": "EV Charging Solutions",
      "itemListElement": [
        {
          "@type": "Offer",
          "itemOffered": {
            "@type": "Product",
            "name": "Home EV Charging Points",
            "description": "Up to 7.4kW fast charging for residential properties"
          }
        },
        {
          "@type": "Offer",
          "itemOffered": {
            "@type": "Product",
            "name": "Commercial EV Charging",
            "description": "Multiple charging point solutions for businesses"
          }
        }
      ]
    },
    "additionalProperty": {
      "@type": "PropertyValue",
      "name": "Certification",
      "value": "OZEV Approved Installer"
    }
  };

  const calculateQuote = () => {
    const selectedCharger = chargerTypes.find(c => c.id === quoteData.chargerType);
    let subtotal = selectedCharger?.basePrice || 0;

    // Add standard installation cost
    subtotal += installationCosts.standardCosts;
    
    // Add cable length costs if over 10m
    const cableOption = cableLengthOptions.find(c => c.id === quoteData.cableLength);
    if (cableOption && cableOption.value !== 'under10') {
      subtotal += cableOption.meters * installationCosts.cablePerMeter;
    }

    // Add any additional work costs
    quoteData.additionalWork.forEach(workId => {
      const work = additionalOptions.find(o => o.id === workId);
      if (work) {
        subtotal += work.price;
      }
    });

    const totalWithVAT = subtotal * 1.2;
    
    return {
      subtotal: subtotal,
      totalWithVAT: totalWithVAT,
      breakdown: {
        equipment: selectedCharger?.basePrice || 0,
        standardCosts: installationCosts.standardCosts,
        cabling: cableOption ? cableOption.meters * installationCosts.cablePerMeter : 0,
        additionalWork: quoteData.additionalWork.reduce((sum, workId) => {
          const work = additionalOptions.find(o => o.id === workId);
          return sum + (work?.price || 0);
        }, 0)
      }
    };
  };

  const formatCurrency = (value) => {
    return new Intl.NumberFormat('en-GB', {
      style: 'currency',
      currency: 'GBP'
    }).format(value);
  };

  const handleQuoteEmail = async (quoteData, quote) => {
    const selectedCharger = chargerTypes.find(c => c.id === quoteData.chargerType);
    const clientDetails = {
      name: `${quoteData.firstName} ${quoteData.lastName}`,
      email: quoteData.email,
      phone: quoteData.phone,
      address: quoteData.address,
      postcode: quoteData.postcode
    };

    const quoteDetails = {
      type: 'EV Charger Installation',
      charger: {
        name: selectedCharger.name,
        type: selectedCharger.type,
        price: selectedCharger.basePrice
      },
      installation: installationCosts.standardCosts,
      additionalCosts: {
        cabling: quote.breakdown.cabling,
        additionalWork: quote.breakdown.additionalWork
      },
      totalWithVAT: quote.totalWithVAT,
      propertyDetails: {
        type: quoteData.propertyType,
        ownership: quoteData.ownership,
        hasParking: quoteData.hasParking,
        consumerUnit: quoteData.consumerUnit
      },
      consumerUnitPhoto: quoteData.consumerUnitPhoto
    };

    try {
      await sendQuoteEmail(clientDetails, quoteDetails);
      return true;
    } catch (error) {
      console.error('Error sending quote email:', error);
      return false;
    }
  };

  const handleInputChange = (field, value) => {
    setQuoteData(prev => ({
      ...prev,
      [field]: value
    }));
    
    // For charger selection, scroll to next button on mobile
    if (field === 'chargerType' && value) {
      const nextButton = document.querySelector('.quote-navigation');
      if (nextButton) {
        nextButton.style.opacity = '1';
        nextButton.style.pointerEvents = 'auto';
        // Check if we're on mobile (screen width <= 768px)
        if (window.innerWidth <= 768) {
          setTimeout(() => {
            nextButton.scrollIntoView({ behavior: 'smooth', block: 'center' });
          }, 100);
        }
      }
    }
  };

  const handleFileUpload = (event) => {
    const file = event.target.files[0];
    if (file) {
      // Convert file to base64
      const reader = new FileReader();
      reader.onloadend = () => {
        const base64String = reader.result;
        handleInputChange('consumerUnitPhoto', {
          name: file.name,
          type: file.type,
          base64: base64String
        });
      };
      reader.readAsDataURL(file);
    }
  };

  const handleNext = async () => {
    // First update the step
    if (step === 5) {
      const quoteResult = calculateQuote();
      setQuote(quoteResult);
      console.log('Quote Data:', { ...quoteData, quote: quoteResult });
      
      // Progress to next step immediately
      setStep(prev => Math.min(prev + 1, 6));

      // Send email in background
      try {
        const emailSent = await handleQuoteEmail(quoteData, quoteResult);
        if (emailSent) {
          console.log('Quote email sent successfully');
        } else {
          console.error('Failed to send quote email');
        }
      } catch (error) {
        console.error('Error sending quote email:', error);
      }
    } else {
      setStep(prev => Math.min(prev + 1, 6));
    }

    // Then scroll to top after a brief delay to ensure new content is rendered
    setTimeout(() => {
      window.scrollTo({ top: 0, behavior: 'smooth' });
    }, 100);
  };

  const handleBack = () => {
    setStep(prev => Math.max(prev - 1, 1));
    
    // Scroll to top after a brief delay
    setTimeout(() => {
      window.scrollTo({ top: 0, behavior: 'smooth' });
    }, 100);
  };

  const isStepValid = () => {
    switch (step) {
      case 1:
        return !!quoteData.chargerType;
      case 2:
        return true;
      case 3:
        return !!quoteData.propertyType;
      case 4:
        return true;
      case 5:
        return (
          quoteData.firstName &&
          quoteData.lastName &&
          quoteData.email &&
          quoteData.phone &&
          quoteData.address &&
          quoteData.postcode
        );
      default:
        return true;
    }
  };

  const renderStep = () => {
    switch (step) {
      case 1:
        return (
          <div className="quote-step">
            <h3>Select Your Charger</h3>
            <div className="octopus-info">
              <p>
                All our chargers are compatible with Octopus Energy's Intelligent EV tariff. 
                <a 
                  href="https://octopus.energy/ev-tariffs/" 
                  target="_blank" 
                  rel="noopener noreferrer"
                > Learn more about smart charging with Octopus Energy →</a>
              </p>
            </div>
            <div className="options-grid">
              {chargerTypes.map(charger => {
                const displayPriceIncVAT = charger.basePrice * 1.2;
                return (
                  <div
                    key={charger.id}
                    className={`option-card ${quoteData.chargerType === charger.id ? 'selected' : ''}`}
                    onClick={() => handleInputChange('chargerType', charger.id)}
                  >
                    <h4>{charger.name}</h4>
                    <p className="charger-type">{charger.type}</p>
                    <p className="price">From {formatCurrency(displayPriceIncVAT)}</p>
                    <ul>
                      {charger.features.map((feature, index) => (
                        <li key={index}>{feature}</li>
                      ))}
                    </ul>
                  </div>
                );
              })}
            </div>
            <div className="price-note">
              <p>* Additional costs may apply for extra cabling or special requirements.</p>
              <p>* Our installations don't include digging, groundworks or lifting of floorboards. These will need to be arranged separately.</p>
            </div>
          </div>
        );

      case 2:
        return (
          <div className="quote-step">
            <h3>Installation Requirements</h3>
            <div className="form-group">
              <label>Approximate cable length needed</label>
              <div className="radio-group">
                {cableLengthOptions.map(option => (
                  <label key={option.id} className="radio-label">
                    <input
                      type="radio"
                      checked={quoteData.cableLength === option.id}
                      onChange={() => handleInputChange('cableLength', option.id)}
                    />
                    {option.label}
                  </label>
                ))}
              </div>
              <p className="cable-note">* Additional cable lengths beyond 10 meters will incur extra costs for materials and labour</p>
            </div>
            <div className="form-group">
              <label>Do you have WiFi where the charger will be installed?</label>
              <div className="radio-group">
                <label className="radio-label">
                  <input
                    type="radio"
                    checked={quoteData.wifiConnection}
                    onChange={() => handleInputChange('wifiConnection', true)}
                  />
                  Yes
                </label>
                <label className="radio-label">
                  <input
                    type="radio"
                    checked={!quoteData.wifiConnection}
                    onChange={() => handleInputChange('wifiConnection', false)}
                  />
                  No
                </label>
              </div>
            </div>
          </div>
        );

      case 3:
        return (
          <div className="quote-step">
            <h3>Property Details</h3>
            <div className="form-group">
              <label>Property Type</label>
              <select
                value={quoteData.propertyType}
                onChange={(e) => handleInputChange('propertyType', e.target.value)}
              >
                <option value="">Select property type</option>
                {propertyTypes.map(type => (
                  <option key={type} value={type}>{type}</option>
                ))}
              </select>
            </div>
            <div className="form-group">
              <label>Property Ownership</label>
              <div className="radio-group">
                <label className="radio-label">
                  <input
                    type="radio"
                    checked={quoteData.ownership === 'owned'}
                    onChange={() => handleInputChange('ownership', 'owned')}
                  />
                  I own the property
                </label>
                <label className="radio-label">
                  <input
                    type="radio"
                    checked={quoteData.ownership === 'rented'}
                    onChange={() => handleInputChange('ownership', 'rented')}
                  />
                  I rent the property
                </label>
              </div>
            </div>
            <div className="form-group">
              <label>Do you have dedicated off-street parking?</label>
              <div className="radio-group">
                <label className="radio-label">
                  <input
                    type="radio"
                    checked={quoteData.hasParking}
                    onChange={() => handleInputChange('hasParking', true)}
                  />
                  Yes
                </label>
                <label className="radio-label">
                  <input
                    type="radio"
                    checked={!quoteData.hasParking}
                    onChange={() => handleInputChange('hasParking', false)}
                  />
                  No
                </label>
              </div>
            </div>
          </div>
        );

      case 4:
        return (
          <div className="quote-step">
            <h3>Consumer Unit Details</h3>
            <div className="form-group">
              <label>Consumer Unit (Fuse Box) Type</label>
              <div className="radio-group">
                <label className="radio-label">
                  <input
                    type="radio"
                    checked={quoteData.consumerUnit === 'modern'}
                    onChange={() => handleInputChange('consumerUnit', 'modern')}
                  />
                  Modern (Past 10 years)
                </label>
                <label className="radio-label">
                  <input
                    type="radio"
                    checked={quoteData.consumerUnit === 'old'}
                    onChange={() => handleInputChange('consumerUnit', 'old')}
                  />
                  Older than 10 years
                </label>
                <label className="radio-label">
                  <input
                    type="radio"
                    checked={quoteData.consumerUnit === 'unknown'}
                    onChange={() => handleInputChange('consumerUnit', 'unknown')}
                  />
                  I'm not sure
                </label>
              </div>
            </div>
            <div className="form-group">
              <label>Upload Consumer Unit Photo</label>
              <div className="file-upload">
                <input
                  type="file"
                  accept="image/*"
                  onChange={handleFileUpload}
                  className="file-input"
                />
                <p className="file-help">Please upload a clear photo of your consumer unit/fuse box</p>
              </div>
            </div>
            <div className="checkbox-group">
              {additionalOptions.map(option => (
                <label key={option.id} className="checkbox-label">
                  <input
                    type="checkbox"
                    checked={quoteData.additionalWork.includes(option.id)}
                    onChange={(e) => {
                      if (e.target.checked) {
                        handleInputChange('additionalWork', [...quoteData.additionalWork, option.id]);
                      } else {
                        handleInputChange('additionalWork', 
                          quoteData.additionalWork.filter(id => id !== option.id)
                        );
                      }
                    }}
                  />
                  {option.name} (+£{option.price})
                </label>
              ))}
            </div>
          </div>
        );

      case 5:
        return (
          <div className="quote-step">
            <h3>Your Details</h3>
            <div className="form-group">
              <label>First Name</label>
              <input
                type="text"
                value={quoteData.firstName}
                onChange={(e) => handleInputChange('firstName', e.target.value)}
                required
              />
            </div>
            <div className="form-group">
              <label>Last Name</label>
              <input
                type="text"
                value={quoteData.lastName}
                onChange={(e) => handleInputChange('lastName', e.target.value)}
                required
              />
            </div>
            <div className="form-group">
              <label>Email</label>
              <input
                type="email"
                value={quoteData.email}
                onChange={(e) => handleInputChange('email', e.target.value)}
                required
              />
            </div>
            <div className="form-group">
              <label>Phone</label>
              <input
                type="tel"
                value={quoteData.phone}
                onChange={(e) => handleInputChange('phone', e.target.value)}
                required
              />
            </div>
            <div className="form-group">
              <label>Installation Address</label>
              <textarea
                value={quoteData.address}
                onChange={(e) => handleInputChange('address', e.target.value)}
                required
              />
            </div>
            <div className="form-group">
              <label>Postcode</label>
              <input
                type="text"
                value={quoteData.postcode}
                onChange={(e) => handleInputChange('postcode', e.target.value)}
                required
              />
            </div>
          </div>
        );

      case 6:
        return (
          <div className="quote-step quote-result">
            <h3>Your Quote</h3>
            {quote && (
              <>
                <div className="selected-options">
                  <h4>Selected Charger</h4>
                  {(() => {
                    const selectedCharger = chargerTypes.find(c => c.id === quoteData.chargerType);
                    return (
                      <div className="selected-charger">
                        <h5>{selectedCharger.name}</h5>
                        <p className="charger-type">{selectedCharger.type}</p>
                        <ul>
                          {selectedCharger.features.map((feature, index) => (
                            <li key={index}>{feature}</li>
                          ))}
                        </ul>
                      </div>
                    );
                  })()}

                  <h4>Installation Details</h4>
                  <div className="installation-details">
                    <p><strong>Cable Length:</strong> {cableLengthOptions.find(c => c.id === quoteData.cableLength).label}</p>
                    <p><strong>Property Type:</strong> {quoteData.propertyType}</p>
                    <p><strong>Property Ownership:</strong> {quoteData.ownership === 'owned' ? 'Owner' : 'Tenant'}</p>
                    <p><strong>Off-street Parking:</strong> {quoteData.hasParking ? 'Yes' : 'No'}</p>
                    <p><strong>WiFi Available:</strong> {quoteData.wifiConnection ? 'Yes' : 'No'}</p>
                    <p><strong>Consumer Unit Type:</strong> {
                      quoteData.consumerUnit === 'modern' ? 'Modern (Past 10 years)' :
                      quoteData.consumerUnit === 'old' ? 'Older than 10 years' :
                      'Not sure'
                    }</p>
                    {quoteData.additionalWork.length > 0 && (
                      <>
                        <p><strong>Additional Works Required:</strong></p>
                        <ul>
                          {quoteData.additionalWork.map(workId => {
                            const work = additionalOptions.find(o => o.id === workId);
                            return <li key={workId}>{work.name}</li>;
                          })}
                        </ul>
                      </>
                    )}
                  </div>
                </div>

                <div className="quote-breakdown">
                  <h4>Cost Breakdown</h4>
                  <div className="quote-item">
                    <span>Equipment (Charger)</span>
                    <span>{formatCurrency(quote.breakdown.equipment)}</span>
                  </div>
                  <div className="quote-item">
                    <span>Standard Installation</span>
                    <span>{formatCurrency(quote.breakdown.standardCosts)}</span>
                  </div>
                  {quote.breakdown.cabling > 0 && (
                    <div className="quote-item">
                      <span>Additional Cabling</span>
                      <span>{formatCurrency(quote.breakdown.cabling)}</span>
                    </div>
                  )}
                  {quote.breakdown.additionalWork > 0 && (
                    <div className="quote-item">
                      <span>Additional Works</span>
                      <span>{formatCurrency(quote.breakdown.additionalWork)}</span>
                    </div>
                  )}
                  <div className="quote-item total">
                    <span>Total (Inc. VAT)</span>
                    <span>{formatCurrency(quote.totalWithVAT)}</span>
                  </div>
                </div>

                <div className="quote-confirmation">
                  <h4>Thank you for your enquiry!</h4>
                  <p>We've sent a detailed quote to your email:</p>
                  <p className="email-highlight">{quoteData.email}</p>
                  <p>This quote is valid for 30 days from today.</p>
                  <p>One of our team members will contact you shortly to discuss the installation details and answer any questions you may have.</p>
                  <p>For any immediate queries, please contact us:</p>
                  <div className="company-details">
                    <p><strong>Phone:</strong> <a href="tel:01473728352">01473 728352</a></p>
                    <p><strong>Email:</strong> <a href="mailto:info@bre-services.com">info@bre-services.com</a></p>
                    <p><strong>Address:</strong><br />
                    Brunswick Electrical Services Ltd<br />
                    173 Brunswick Road<br />
                    Ipswich, Suffolk IP4 4DB</p>
                    <p><strong>Company Details:</strong><br />
                    Company number: 07100422<br />
                    VAT Registration: 997 005 100</p>
                  </div>
                  <div className="copyright">
                    <p>Copyright © 2024 Brunswick Electrical Services Ltd - All Rights Reserved.</p>
                  </div>
                </div>

                <div className="quote-notes">
                  <p><strong>Important Notes:</strong></p>
                  <ul>
                    <li>This quote assumes a standard installation. Additional works may be identified during our pre-installation survey.</li>
                    <li>DNO charges for unlooping or three-phase connections are not included in this quote.</li>
                    <li>Our installations don't include digging, groundworks or lifting of floorboards. These will need to be arranged separately.</li>
                    {quoteData.ownership === 'rented' && (
                      <li>Landlord's permission will be required for installation.</li>
                    )}
                  </ul>
                </div>
              </>
            )}
          </div>
        );

      default:
        return null;
    }
  };

  return (
    <>
      <SEO 
        title="EV Charging Point Installation Quote Calculator"
        description="Get an instant quote for EV charger installation in Ipswich. OZEV approved installer, NICEIC certified. Professional installation for home and business charging points."
        canonical="/ev-quote"
        schema={evQuoteSchema}
        type="website"
      />
      <div className="ev-quote-calculator full-page">
        <div className="quote-progress">
          <div className="progress-bar">
            <div className="progress" style={{ width: `${(step / 6) * 100}%` }}></div>
          </div>
          <div className="steps">
            <span className={step >= 1 ? 'active' : ''}>Charger</span>
            <span className={step >= 2 ? 'active' : ''}>Installation</span>
            <span className={step >= 3 ? 'active' : ''}>Property</span>
            <span className={step >= 4 ? 'active' : ''}>Additional</span>
            <span className={step >= 5 ? 'active' : ''}>Details</span>
            <span className={step >= 6 ? 'active' : ''}>Quote</span>
          </div>
        </div>
        {renderStep()}
        <div className={`quote-navigation ${!quoteData.chargerType && step === 1 ? 'hidden' : ''}`}>
          {step > 1 && (
            <button className="secondary-button" onClick={handleBack}>
              Back
            </button>
          )}
          {step < 6 && (
            <button 
              className="primary-button" 
              onClick={handleNext}
              disabled={!isStepValid()}
            >
              {step === 5 ? 'Get Quote' : 'Next'}
            </button>
          )}
        </div>
      </div>
    </>
  );
};

export default EVQuoteCalculator; 