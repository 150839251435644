import React from 'react';
import '../styles/Accreditations.css';

const Accreditations = () => {
  const accreditations = [
    {
      name: 'NICEIC Approved Contractor',
      logo: '/images/accreditations/niceic.jpg',
      alt: 'NICEIC Approved Contractor Logo - Certified Electrical Safety',
      link: 'https://www.niceic.com'
    },
    {
      name: 'F-Gas Registered',
      logo: '/images/accreditations/fgas.jpg',
      alt: 'F-Gas Certification Logo - Registered Air Conditioning Engineer',
      link: 'https://fgasregister.com'
    },
    {
      name: 'Checkatrade Verified',
      logo: '/images/accreditations/checkatrade.webp',
      alt: 'Checkatrade Verified Member Logo - Trusted Trade Professional',
      link: 'https://www.checkatrade.com'
    }
  ];

  return (
    <section className="accreditations">
      <div className="container">
        <h2>Our Accreditations</h2>
        <p className="accreditations-intro">
          We maintain the highest standards through our professional accreditations and certifications
        </p>
        <div className="accreditations-grid">
          {accreditations.map((accred) => (
            <a 
              key={accred.name}
              href={accred.link}
              className="accreditation-card"
              target="_blank"
              rel="noopener noreferrer"
              aria-label={`View our ${accred.name} certification`}
            >
              <img
                src={accred.logo}
                alt={accred.alt}
                loading="lazy"
                width="200"
                height="200"
              />
              <span className="accreditation-name">{accred.name}</span>
            </a>
          ))}
        </div>
      </div>
    </section>
  );
};

export default Accreditations; 