import React from 'react';
import Hero from './Hero';
import Accreditations from './Accreditations';
import ServicesOverview from './ServicesOverview';
import Reviews from './Reviews';
import GoogleBusinessProfile from './GoogleBusinessProfile';
import SEO from './SEO';

const Home = () => {
  const schema = {
    "@context": "https://schema.org",
    "@type": "LocalBusiness",
    "name": "B E Services Ltd",
    "image": "https://www.be-services.com/images/shop-front.jpg",
    "description": "NICEIC Approved electrical contractor and F-Gas registered air conditioning specialist in Ipswich. Expert electrical installations and air conditioning services with 25+ years experience.",
    "@id": "https://www.be-services.com",
    "url": "https://www.be-services.com",
    "telephone": "+441473728352",
    "priceRange": "££",
    "address": {
      "@type": "PostalAddress",
      "streetAddress": "173 Brunswick Road",
      "addressLocality": "Ipswich",
      "addressRegion": "Suffolk",
      "postalCode": "IP4 4DB",
      "addressCountry": "GB"
    },
    "geo": {
      "@type": "GeoCoordinates",
      "latitude": "52.0594",
      "longitude": "1.1556"
    },
    "openingHoursSpecification": [
      {
        "@type": "OpeningHoursSpecification",
        "dayOfWeek": ["Monday", "Tuesday", "Wednesday", "Thursday", "Friday"],
        "opens": "08:00",
        "closes": "17:00"
      }
    ],
    "aggregateRating": {
      "@type": "AggregateRating",
      "ratingValue": "4.9",
      "reviewCount": "150"
    }
  };

  return (
    <>
      <SEO 
        title="Professional Electrical & Air Conditioning Services in Ipswich"
        description="NICEIC Approved electrical contractor and F-Gas registered air conditioning specialist. Expert electrical installations, maintenance, and 24/7 emergency services in Ipswich and Suffolk."
        canonical="/"
        schema={schema}
        type="business.business"
      />
      <Hero />
      <Accreditations />
      <ServicesOverview />
      <Reviews />
      <GoogleBusinessProfile />
    </>
  );
};

export default Home; 