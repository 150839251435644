import React, { useState } from 'react';
import './AboutUs.css';

const AboutUs = () => {
  const [formData, setFormData] = useState({
    firstName: '',
    lastName: '',
    email: '',
    phone: '',
    location: '',
    service: '',
    message: ''
  });

  const services = [
    'Residential Electrical Services',
    'Commercial Electrical Services',
    'Air Conditioning Installation',
    'Air Conditioning Maintenance',
    'Emergency Call-out',
    'EV Charging Points',
    'Victron Energy Solutions'
  ];

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData(prevState => ({
      ...prevState,
      [name]: value
    }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      console.log('Submitting form:', formData);

      const response = await fetch('/.netlify/functions/send-contact', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          firstName: formData.firstName,
          lastName: formData.lastName,
          email: formData.email,
          phone: formData.phone,
          location: formData.location,
          serviceRequired: formData.service,
          message: formData.message
        }),
      });

      if (!response.ok) {
        const errorData = await response.json();
        console.error('Error response from server:', errorData);
        throw new Error(errorData.message || 'Failed to submit form');
      }

      const result = await response.json();
      console.log('Form submitted successfully:', result);
      
      // Clear form
      setFormData({
        firstName: '',
        lastName: '',
        email: '',
        phone: '',
        location: '',
        service: '',
        message: ''
      });

      // Show success message
      alert('Thank you for your message. We will get back to you shortly.');
    } catch (error) {
      console.error('Error submitting form:', error);
      alert(`There was an error submitting your message: ${error.message}. Please try again or contact us at info@bre-services.com`);
    }
  };

  // Format phone number for WhatsApp link (remove spaces and add country code if needed)
  const whatsappNumber = '07798683723'.replace(/\s+/g, '');
  const whatsappMessage = encodeURIComponent('Hi, I would like to enquire about your electrical/air conditioning services.');
  const whatsappLink = `https://wa.me/44${whatsappNumber}?text=${whatsappMessage}`;

  return (
    <div className="about-us">
      <section className="contact-info">
        <div className="container">
          <h1>Contact Us</h1>
          <p className="intro">
            Have questions about our electrical or air conditioning services in Suffolk? 
            Need an emergency electrician in Ipswich? Get in touch with our team and we'll be happy to help.
          </p>

          <div className="contact-grid">
            <div className="contact-card">
              <h2>Phone</h2>
              <a href="tel:01473728352" className="phone-number">01473 728352</a>
              <p className="highlight">24/7 Emergency Electrical Service Available Throughout Suffolk</p>
            </div>

            <div className="contact-card">
              <h2>Email</h2>
              <a href="mailto:info@bre-services.com">info@bre-services.com</a>
              <p>We'll respond to your inquiry as soon as possible</p>
            </div>

            <div className="contact-card">
              <h2>Address</h2>
              <p className="company-name">Brunswick Electrical Services Ltd</p>
              <p>173 Brunswick Road</p>
              <p>Ipswich, Suffolk IP4 4DB</p>
              <p>Company No: 07100422</p>
              <p>VAT No: 997 005 100</p>
            </div>

            <div className="contact-card">
              <h2>Business Hours</h2>
              <ul className="hours-list">
                <li><span>Monday - Friday:</span> 8:00 AM - 5:00 PM</li>
                <li><span>Saturday:</span> By appointment</li>
                <li><span>Sunday:</span> Closed (Emergency services available)</li>
              </ul>
            </div>
          </div>

          <a href={whatsappLink} 
             target="_blank" 
             rel="noopener noreferrer" 
             className="whatsapp-button">
            <i className="whatsapp-icon" aria-hidden="true"></i>
            <span>Chat on WhatsApp</span>
          </a>
        </div>
      </section>

      <section className="service-areas">
        <div className="container">
          <h2>Areas We Serve in Suffolk</h2>
          <div className="areas-grid">
            <div className="area">Ipswich</div>
            <div className="area">Woodbridge</div>
            <div className="area">Felixstowe</div>
            <div className="area">Stowmarket</div>
            <div className="area">Bury St Edmunds</div>
            <div className="area">Surrounding Areas</div>
          </div>
        </div>
      </section>

      <section className="contact-form">
        <div className="container">
          <h2>Send Us a Message</h2>
          <p>Fill out the form below and we'll get back to you as soon as possible about your electrical or air conditioning needs in Suffolk.</p>
          
          <form onSubmit={handleSubmit}>
            <div className="form-grid">
              <div className="form-group">
                <label htmlFor="firstName">First name</label>
                <input
                  type="text"
                  id="firstName"
                  name="firstName"
                  value={formData.firstName}
                  onChange={handleChange}
                  required
                />
              </div>

              <div className="form-group">
                <label htmlFor="lastName">Last name</label>
                <input
                  type="text"
                  id="lastName"
                  name="lastName"
                  value={formData.lastName}
                  onChange={handleChange}
                  required
                />
              </div>

              <div className="form-group">
                <label htmlFor="email">Email</label>
                <input
                  type="email"
                  id="email"
                  name="email"
                  value={formData.email}
                  onChange={handleChange}
                  required
                />
              </div>

              <div className="form-group">
                <label htmlFor="phone">Phone</label>
                <input
                  type="tel"
                  id="phone"
                  name="phone"
                  value={formData.phone}
                  onChange={handleChange}
                  required
                />
              </div>

              <div className="form-group">
                <label htmlFor="location">Your Location</label>
                <input
                  type="text"
                  id="location"
                  name="location"
                  value={formData.location}
                  onChange={handleChange}
                  required
                />
              </div>

              <div className="form-group">
                <label htmlFor="service">Service Required</label>
                <select
                  id="service"
                  name="service"
                  value={formData.service}
                  onChange={handleChange}
                  required
                >
                  <option value="">Select a service</option>
                  {services.map((service) => (
                    <option key={service} value={service}>
                      {service}
                    </option>
                  ))}
                </select>
              </div>
            </div>

            <div className="form-group full-width">
              <label htmlFor="message">Message</label>
              <textarea
                id="message"
                name="message"
                value={formData.message}
                onChange={handleChange}
                placeholder="Please provide details about your electrical or air conditioning requirements in Suffolk..."
                required
                rows="5"
              ></textarea>
            </div>

            <button type="submit" className="submit-button">Send Message</button>
          </form>
        </div>
      </section>

      <section className="accreditations">
        <div className="container">
          <h2>Our Accreditations</h2>
          <p className="intro">
            We maintain the highest standards through our professional accreditations and certifications
          </p>
          <div className="accreditation-grid">
            <div className="accreditation-card">
              <img src="/images/niceic-logo.png" alt="NICEIC Approved Contractor" />
              <h3>NICEIC Approved Contractor</h3>
            </div>
            <div className="accreditation-card">
              <img src="/images/f-gas-logo.png" alt="F-Gas Registered" />
              <h3>F-Gas Registered</h3>
            </div>
            <div className="accreditation-card">
              <img src="/images/checkatrade-logo.png" alt="Checkatrade Verified" />
              <h3>Checkatrade Verified</h3>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
};

export default AboutUs; 