import React from 'react';
import { Helmet } from 'react-helmet-async';
import '../styles/LocationLandingPage.css';

const LocationLandingPage = ({ 
  location, 
  services, 
  testimonials, 
  coordinates,
  description 
}) => {
  const schema = {
    "@context": "https://schema.org",
    "@type": "LocalBusiness",
    "name": `B E Services Ltd - ${location}`,
    "image": "https://www.be-services.com/images/shop-front.jpg",
    "description": `Professional electrical and air conditioning services in ${location}, Suffolk. NICEIC Approved and F-Gas registered.`,
    "@id": `https://www.be-services.com/locations/${location.toLowerCase().replace(/\s+/g, '-')}`,
    "url": `https://www.be-services.com/locations/${location.toLowerCase().replace(/\s+/g, '-')}`,
    "telephone": "+441473728352",
    "priceRange": "££",
    "address": {
      "@type": "PostalAddress",
      "streetAddress": "173 Brunswick Road",
      "addressLocality": "Ipswich",
      "addressRegion": "Suffolk",
      "postalCode": "IP4 4DB",
      "addressCountry": "GB"
    },
    "geo": {
      "@type": "GeoCoordinates",
      "latitude": coordinates.latitude,
      "longitude": coordinates.longitude
    },
    "openingHoursSpecification": [
      {
        "@type": "OpeningHoursSpecification",
        "dayOfWeek": ["Monday", "Tuesday", "Wednesday", "Thursday", "Friday"],
        "opens": "08:00",
        "closes": "17:00"
      }
    ],
    "areaServed": {
      "@type": "City",
      "name": location
    },
    "hasOfferCatalog": {
      "@type": "OfferCatalog",
      "name": `Services in ${location}`,
      "itemListElement": services.map(service => ({
        "@type": "Offer",
        "itemOffered": {
          "@type": "Service",
          "name": service.name,
          "description": service.description,
          "areaServed": {
            "@type": "City",
            "name": location
          }
        }
      }))
    }
  };

  return (
    <div className="location-landing-page">
      <Helmet>
        <title>{`Electrical & Air Conditioning Services in ${location} | B E Services Ltd`}</title>
        <meta name="description" content={`Professional electrical and air conditioning services in ${location}, Suffolk. NICEIC Approved and F-Gas registered. Serving ${location} and surrounding areas.`} />
        <meta name="keywords" content={`electrician ${location}, air conditioning ${location}, electrical services ${location}, emergency electrician ${location}, NICEIC approved ${location}`} />
        <link rel="canonical" href={`https://www.be-services.com/locations/${location.toLowerCase().replace(/\s+/g, '-')}`} />
        <script type="application/ld+json">
          {JSON.stringify(schema)}
        </script>
      </Helmet>

      <section className="location-hero">
        <div className="container">
          <h1>Electrical & Air Conditioning Services in {location}</h1>
          <p className="hero-description">
            Professional electrical and air conditioning services in {location}, Suffolk. 
            NICEIC Approved and F-Gas registered. Serving {location} and surrounding areas.
          </p>
          <div className="cta-buttons">
            <a href="/contact" className="primary-button">Get a Quote</a>
            <a href="tel:01473728352" className="secondary-button">Call Now</a>
          </div>
        </div>
      </section>

      <section className="location-services">
        <div className="container">
          <h2>Our Services in {location}</h2>
          <div className="services-grid">
            {services.map((service, index) => (
              <div key={index} className="service-card">
                <h3>{service.name}</h3>
                <p>{service.description}</p>
                <ul className="service-features">
                  {service.features.map((feature, featureIndex) => (
                    <li key={featureIndex}>{feature}</li>
                  ))}
                </ul>
              </div>
            ))}
          </div>
        </div>
      </section>

      <section className="location-testimonials">
        <div className="container">
          <h2>What Our {location} Customers Say</h2>
          <div className="testimonials-grid">
            {testimonials.map((testimonial, index) => (
              <div key={index} className="testimonial-card">
                <div className="rating">{testimonial.rating} ★</div>
                <p className="testimonial-text">{testimonial.text}</p>
                <div className="testimonial-author">
                  <strong>{testimonial.author}</strong>
                  <span>{testimonial.location}</span>
                </div>
              </div>
            ))}
          </div>
        </div>
      </section>

      <section className="location-contact">
        <div className="container">
          <h2>Contact Us for Services in {location}</h2>
          <div className="contact-grid">
            <div className="contact-info">
              <h3>Get in Touch</h3>
              <p>Need electrical or air conditioning services in {location}? Contact us today!</p>
              <ul className="contact-details">
                <li>📞 <a href="tel:01473728352">01473 728352</a></li>
                <li>✉️ <a href="mailto:info@bre-services.com">info@bre-services.com</a></li>
                <li>📍 173 Brunswick Road, Ipswich, IP4 4DB</li>
              </ul>
            </div>
            <div className="contact-form">
              <form action="/contact" method="POST">
                <input type="hidden" name="location" value={location} />
                <div className="form-group">
                  <input type="text" name="name" placeholder="Your Name" required />
                </div>
                <div className="form-group">
                  <input type="email" name="email" placeholder="Your Email" required />
                </div>
                <div className="form-group">
                  <input type="tel" name="phone" placeholder="Your Phone" required />
                </div>
                <div className="form-group">
                  <textarea name="message" placeholder="Tell us about your requirements" required></textarea>
                </div>
                <button type="submit" className="submit-button">Send Message</button>
              </form>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
};

export default LocationLandingPage; 