import React from 'react';
import { Helmet } from 'react-helmet-async';

const SEO = ({ 
  title, 
  description, 
  image = '/images/shop-front.jpg',
  schema,
  canonical,
  type = 'website'
}) => {
  const siteTitle = 'B E Services Ltd - Electrical & Air Conditioning Services Ipswich';
  const fullTitle = title ? `${title} | ${siteTitle}` : siteTitle;
  const defaultDescription = 'NICEIC Approved electrical contractor and F-Gas registered air conditioning specialist in Ipswich. 25+ years experience in electrical installations, air conditioning, and emergency services.';
  const metaDescription = description || defaultDescription;
  const siteUrl = 'https://www.be-services.com';
  const imageUrl = `${siteUrl}${image}`;
  const canonicalUrl = canonical ? `${siteUrl}${canonical}` : siteUrl;

  // Default schema for LocalBusiness
  const defaultSchema = {
    "@context": "https://schema.org",
    "@type": "LocalBusiness",
    "name": "BRE Services",
    "image": "https://www.be-services.com/images/logo.png",
    "description": "Professional electrical and air conditioning services in Ipswich and Suffolk. Specializing in residential and commercial installations, maintenance, and emergency services.",
    "address": {
      "@type": "PostalAddress",
      "streetAddress": "123 Main Street",
      "addressLocality": "Ipswich",
      "addressRegion": "Suffolk",
      "postalCode": "IP1 1AA",
      "addressCountry": "GB"
    },
    "geo": {
      "@type": "GeoCoordinates",
      "latitude": "52.0592",
      "longitude": "1.1554"
    },
    "url": "https://www.be-services.com",
    "telephone": "+441473123456",
    "openingHoursSpecification": [
      {
        "@type": "OpeningHoursSpecification",
        "dayOfWeek": ["Monday", "Tuesday", "Wednesday", "Thursday", "Friday"],
        "opens": "08:00",
        "closes": "17:00"
      },
      {
        "@type": "OpeningHoursSpecification",
        "dayOfWeek": ["Saturday"],
        "opens": "09:00",
        "closes": "13:00"
      }
    ],
    "sameAs": [
      "https://www.facebook.com/breservices",
      "https://www.instagram.com/breservices",
      "https://www.linkedin.com/company/bre-services"
    ],
    "hasOfferCatalog": {
      "@type": "OfferCatalog",
      "name": "Services",
      "itemListElement": [
        {
          "@type": "Offer",
          "itemOffered": {
            "@type": "Service",
            "name": "Electrical Services",
            "description": "Professional electrical installation and maintenance services for residential and commercial properties.",
            "areaServed": {
              "@type": "City",
              "name": "Ipswich"
            }
          }
        },
        {
          "@type": "Offer",
          "itemOffered": {
            "@type": "Service",
            "name": "Air Conditioning",
            "description": "Expert air conditioning installation and maintenance services, including Panasonic's premium Etherea range.",
            "areaServed": {
              "@type": "City",
              "name": "Ipswich"
            }
          }
        },
        {
          "@type": "Offer",
          "itemOffered": {
            "@type": "Service",
            "name": "EV Charging Installation",
            "description": "Professional installation of electric vehicle charging points for homes and businesses.",
            "areaServed": {
              "@type": "City",
              "name": "Ipswich"
            }
          }
        },
        {
          "@type": "Offer",
          "itemOffered": {
            "@type": "Service",
            "name": "Emergency Services",
            "description": "24/7 emergency electrical and air conditioning repair services.",
            "areaServed": {
              "@type": "City",
              "name": "Ipswich"
            }
          }
        }
      ]
    },
    "areaServed": {
      "@type": "City",
      "name": "Ipswich"
    },
    "aggregateRating": {
      "@type": "AggregateRating",
      "ratingValue": "4.8",
      "reviewCount": "150"
    },
    "review": {
      "@type": "Review",
      "reviewRating": {
        "@type": "Rating",
        "ratingValue": "5",
        "bestRating": "5"
      },
      "author": {
        "@type": "Person",
        "name": "John Smith"
      },
      "reviewBody": "Excellent service from start to finish. Very professional and knowledgeable team."
    },
    "paymentAccepted": ["Cash", "Credit Card", "Bank Transfer"],
    "foundingDate": "2010",
    "numberOfEmployees": "10-50"
  };

  return (
    <Helmet>
      {/* Basic meta tags */}
      <title>{fullTitle}</title>
      <meta name="description" content={metaDescription} />
      <link rel="canonical" href={canonicalUrl} />
      <meta name="keywords" content="electrical services, air conditioning, NICEIC approved, F-Gas registered, Ipswich, Suffolk, emergency electrician, EV charging, air conditioning installation" />
      <meta name="author" content="B E Services Ltd" />
      <meta name="robots" content="index, follow" />
      <meta name="language" content="English" />

      {/* OpenGraph meta tags */}
      <meta property="og:site_name" content="B E Services Ltd" />
      <meta property="og:title" content={fullTitle} />
      <meta property="og:description" content={metaDescription} />
      <meta property="og:image" content={imageUrl} />
      <meta property="og:type" content={type} />
      <meta property="og:url" content={canonicalUrl} />
      <meta property="og:locale" content="en_GB" />

      {/* Twitter Card meta tags */}
      <meta name="twitter:card" content="summary_large_image" />
      <meta name="twitter:title" content={fullTitle} />
      <meta name="twitter:description" content={metaDescription} />
      <meta name="twitter:image" content={imageUrl} />
      <meta name="twitter:site" content="@beservicesltd" />

      {/* Additional meta tags */}
      <meta name="geo.region" content="GB-SFK" />
      <meta name="geo.placename" content="Ipswich" />
      <meta name="geo.position" content="52.0594;1.1556" />
      <meta name="ICBM" content="52.0594, 1.1556" />
      <meta name="geo.region" content="GB-SFK" />
      <meta name="geo.placename" content="Ipswich" />
      <meta name="geo.position" content="52.0594;1.1556" />
      <meta name="ICBM" content="52.0594, 1.1556" />

      {/* Mobile meta tags */}
      <meta name="viewport" content="width=device-width, initial-scale=1, maximum-scale=5" />
      <meta name="theme-color" content="#1e3c72" />
      <meta name="apple-mobile-web-app-capable" content="yes" />
      <meta name="apple-mobile-web-app-status-bar-style" content="black" />

      {/* Schema.org structured data */}
      <script type="application/ld+json">
        {JSON.stringify(schema || defaultSchema)}
      </script>
    </Helmet>
  );
};

export default SEO; 