import React from 'react';
import { Link } from 'react-router-dom';
import { Phone, Calendar } from 'lucide-react';

const FloatingButtons = () => {
  return (
    <div className="floating-buttons">
      <Link to="/contact" className="floating-button book">
        <Calendar size={24} />
        <span>Book Now</span>
      </Link>
      <a href="tel:01473728352" className="floating-button call">
        <Phone size={24} />
        <span>Call Us</span>
      </a>
    </div>
  );
};

export default FloatingButtons; 