import React, { useMemo } from 'react';
import { Link, useLocation } from 'react-router-dom';
import './ServicesOverview.css';

const ServicesOverview = () => {
  const location = useLocation();
  const isHomePage = location.pathname === '/';

  const services = useMemo(() => {
    if (isHomePage) {
      return [];
    }
    
    return [
      {
        title: 'Residential Electrical Services',
        subtitle: 'Complete electrical solutions for your home in Suffolk',
        features: [
          'Full House Installations & Rewiring',
          'Expert Fault Finding & Repairs',
          'Electrical Safety Inspections & Certificates',
          'Modern Consumer Unit Upgrades',
          'EV Charging Points',
          'Emergency Power Solutions'
        ],
        ctaText: 'Contact Now',
        icon: '⚡',
        link: '/contact'
      },
      {
        title: 'Air Conditioning',
        subtitle: 'Expert air conditioning installation and maintenance in Ipswich',
        features: [
          'Custom System Design & Professional Installation',
          'Regular Maintenance & Servicing Plans',
          'Fast Repairs & Comprehensive Troubleshooting',
          'Energy Efficient Climate Control Solutions',
          'Domestic & Commercial Systems',
          'F-Gas Registered Engineers'
        ],
        ctaText: 'Get Instant Quote',
        icon: '❄️',
        link: '/air-con-quote'
      },
      {
        title: '24/7 Emergency Call-outs',
        subtitle: 'Rapid response electrical services in Suffolk when you need it most',
        features: [
          '24/7 Emergency Response Throughout Suffolk',
          'Quick Power Outage Resolution',
          'Thorough Electrical Fault Diagnosis',
          'Reliable Temporary Power Solutions',
          'Same-Day Emergency Repairs',
          'Professional Safety Assessments'
        ],
        ctaText: 'Contact Now',
        icon: '🚨',
        link: '/contact'
      },
      {
        title: 'EV Charging Solutions',
        description: 'Professional installation of home and commercial EV charging points. We offer:',
        features: [
          'Expert installation from NICEIC approved electricians',
          'Compatible with all electric vehicle types',
          'Smart charging capabilities with app control',
          'Up to 7.4kW fast charging',
          'Commercial charging solutions for businesses',
          'Full warranty and aftercare support'
        ],
        benefits: [
          'Save up to 87% compared to public charging costs',
          'Charge your EV overnight at lower rates',
          'Increase your property value',
          'Future-proof your home/business'
        ],
        ctaText: 'Get Instant Quote',
        icon: '🔌',
        link: '/ev-quote'
      }
    ];
  }, [isHomePage]);

  // Add structured data for SEO
  React.useEffect(() => {
    const structuredData = {
      "@context": "https://schema.org",
      "@type": "LocalBusiness",
      "name": "B E Services Ltd",
      "description": "Professional electrical and air conditioning services in Suffolk",
      "address": {
        "@type": "PostalAddress",
        "addressLocality": "Ipswich",
        "addressRegion": "Suffolk",
        "addressCountry": "UK"
      },
      "hasOfferCatalog": {
        "@type": "OfferCatalog",
        "name": "Electrical Services",
        "itemListElement": services.map((service) => ({
          "@type": "Offer",
          "itemOffered": {
            "@type": "Service",
            "name": service.title,
            "description": service.subtitle
          }
        }))
      }
    };

    const script = document.createElement('script');
    script.type = 'application/ld+json';
    script.text = JSON.stringify(structuredData);
    document.head.appendChild(script);

    return () => {
      document.head.removeChild(script);
    };
  }, [services]);

  if (isHomePage && services.length === 0) {
    return null;
  }

  return (
    <section className="services-overview" aria-label="Our Services">
      <div className="services-container">
        <h1>Our Electrical & Air Conditioning Services</h1>
        <p className="services-intro">
          Brunswick Electrical Services Ltd provides comprehensive electrical and air conditioning solutions 
          for residential and commercial properties throughout Ipswich and Suffolk.
        </p>
        <div className="services-grid">
          {services.map((service, index) => (
            <div 
              key={index} 
              className="service-card"
              role="article"
              aria-labelledby={`service-title-${index}`}
            >
              <div className="service-icon" role="img" aria-label={service.title}>
                {service.icon}
              </div>
              <h2 id={`service-title-${index}`}>{service.title}</h2>
              <p className="service-subtitle">{service.subtitle}</p>
              <p>{service.description}</p>
              {service.features && (
                <ul className="service-features" aria-label={`${service.title} features`}>
                  {service.features.map((feature, featureIndex) => (
                    <li key={featureIndex}>{feature}</li>
                  ))}
                </ul>
              )}
              {service.benefits && (
                <div className="benefits-section">
                  <h3>Benefits</h3>
                  <ul className="benefits-list">
                    {service.benefits.map((benefit, benefitIndex) => (
                      <li key={benefitIndex}>{benefit}</li>
                    ))}
                  </ul>
                </div>
              )}
              <Link to={service.link} className="cta-button">
                {service.ctaText}
              </Link>
            </div>
          ))}
        </div>
      </div>
    </section>
  );
};

export default ServicesOverview; 