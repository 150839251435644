import React, { useState, useEffect, useMemo, useCallback } from 'react';
import { CheckCircle, Info } from 'lucide-react';
import { sendQuoteEmail } from '../services/emailService';
import './AirConQuoteCalculator.css';

const AirConQuoteCalculator = () => {
  // Main state for the quote process
  const [step, setStep] = useState(1);
  const [propertyType, setPropertyType] = useState("");
  const [rooms, setRooms] = useState([]);
  const [contactInfo, setContactInfo] = useState({
    name: "",
    email: "",
    phone: "",
    address: "",
    postcode: "",
  });
  const [quoteDetails, setQuoteDetails] = useState(null);
  const [selectedUnitTypes, setSelectedUnitTypes] = useState([]);
  const [showTooltip, setShowTooltip] = useState(null);
  const [toast, setToast] = useState({ show: false, message: '' });
  const [selectedSystemType, setSelectedSystemType] = useState('single-split');
  const [totalCost, setTotalCost] = useState(0);

  // Scroll to top when component mounts
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  // Scroll to top when step changes
  useEffect(() => {
    window.scrollTo({ top: 0, behavior: 'smooth' });
  }, [step]);

  // Steps for the progress bar
  const steps = [
    { id: 1, name: "Your home" },
    { id: 2, name: "Options" },
    { id: 3, name: "Details" },
    { id: 4, name: "System Type" },
    { id: 5, name: "Quote" },
  ];

  // Room size definitions with kW requirements
  const roomSizes = useMemo(() => ({
    small: { name: "Small", area: "Up to 15m²", cooling: 2.5, heating: 3.0, description: "Approx. 3m × 5m", tooltip: "Suitable for small bedrooms, home offices, or small kitchens. Typical energy usage: £0.30-0.40/hour" },
    medium: { name: "Medium", area: "15-25m²", cooling: 3.5, heating: 4.0, description: "Approx. 4m × 6m", tooltip: "Perfect for master bedrooms, average living rooms, or medium kitchens. Typical energy usage: £0.40-0.50/hour" },
    large: { name: "Large", area: "25-35m²", cooling: 5.0, heating: 5.6, description: "Approx. 5m × 7m", tooltip: "Ideal for large living rooms, open-plan kitchens, or small open-plan areas. Typical energy usage: £0.50-0.70/hour" },
    extraLarge: { name: "Extra Large", area: "Over 35m²", cooling: 7.1, heating: 8.0, description: "Larger than 5m × 7m", tooltip: "Suitable for large open-plan areas, restaurant spaces, or commercial rooms. Typical energy usage: £0.70-1.00/hour" },
  }), []); // Empty dependency array since this is static data

  // Property type options with illustrations
  const propertyTypes = [
    {
      id: "detached",
      name: "Detached",
      description: "A house that doesn't share any walls with another property.",
      image: "/images/property-types/detached.jpg",
      tooltip: "Perfect for maximum flexibility in unit placement and installation options."
    },
    {
      id: "semi-detached",
      name: "Semi-Detached",
      description: "Your house is one of a pair that share a single common wall.",
      image: "/images/property-types/semi-detached.jpg",
      tooltip: "Good flexibility for unit placement on three sides of the property."
    },
    {
      id: "terraced",
      name: "Terraced",
      description: "Your house shares walls with properties on both sides.",
      image: "/images/property-types/terraced.jpg",
      tooltip: "Units can be placed at front, back, or potentially on the roof."
    },
    {
      id: "flat",
      name: "Flat/Apartment",
      description: "A self-contained housing unit in a building with multiple units.",
      image: "/images/property-types/apartment.jpg",
      tooltip: "Special considerations needed for unit placement and building regulations."
    },
    {
      id: "bungalow",
      name: "Bungalow",
      description: "A single-story house with no stairs.",
      image: "/images/property-types/bungalow.jpg",
      tooltip: "Excellent for easy installation and maintenance access."
    },
    {
      id: "commercial",
      name: "Commercial",
      description: "Office, shop, or other business premises.",
      image: "/images/property-types/commercial.jpg",
      tooltip: "Custom solutions available for various business needs."
    },
  ];

  // Room type options
  const roomTypeOptions = [
    {
      id: "living-room",
      name: "Living Room",
      image: "/images/rooms/living-room.jpg",
      tooltip: "Main living space - consider room usage times and sun exposure"
    },
    {
      id: "bedroom",
      name: "Bedroom",
      image: "/images/rooms/bedroom.jpg",
      tooltip: "Quiet operation important - recommended 18-21°C for sleeping"
    },
    {
      id: "kitchen",
      name: "Kitchen",
      image: "/images/rooms/kitchen.jpg",
      tooltip: "Consider additional heat from cooking - may need extra cooling capacity"
    },
    {
      id: "dining-room",
      name: "Dining Room",
      image: "/images/rooms/dining-room.jpg",
      tooltip: "Consider peak usage times and number of occupants"
    },
    {
      id: "conservatory",
      name: "Conservatory",
      image: "/images/rooms/conservatory.jpg",
      tooltip: "High solar gain - may need increased cooling capacity"
    },
    {
      id: "home-office",
      name: "Home Office",
      image: "/images/rooms/home-office.jpg",
      tooltip: "Consider equipment heat output and daily usage"
    },
  ];

  // Single split systems data
  const singleSplitSystems = [
    // TZ Series (Basic Range)
    {
      id: "tz-25",
      model: "Panasonic TZ Compact 2.5kW",
      type: "Wall Mounted",
      cooling: 2.5,
      heating: 3.3,
      listPrice: 1083,
      discountedPrice: 628,
      installationCost: 600,
      finalPrice: 1228,
      image: "/images/unit/indoor & outdoor /INDOOR_CS-TZ35ZKEW.png",
      outdoorImage: "/images/unit/indoor & outdoor /OUTDOOR_CU-TZ35ZKE.png",
      energyRating: "A++",
      features: ["2.5kW cooling capacity", "3.3kW heating capacity", "Energy rating A++", "Super quiet operation", "Built-in WiFi"],
    },
    {
      id: "tz-35",
      model: "Panasonic TZ Compact 3.5kW",
      type: "Wall Mounted",
      cooling: 3.5,
      heating: 4.0,
      listPrice: 1252,
      discountedPrice: 726,
      installationCost: 600,
      finalPrice: 1326,
      image: "/images/unit/indoor & outdoor /INDOOR_CS-TZ35ZKEW.png",
      outdoorImage: "/images/unit/indoor & outdoor /OUTDOOR_CU-TZ35ZKE.png",
      energyRating: "A++",
      features: ["3.5kW cooling capacity", "4.0kW heating capacity", "Energy rating A++", "Super quiet operation", "Built-in WiFi"],
    },
    {
      id: "tz-50",
      model: "Panasonic TZ Compact 5.0kW",
      type: "Wall Mounted",
      cooling: 5.0,
      heating: 5.8,
      listPrice: 1593,
      discountedPrice: 924,
      installationCost: 600,
      finalPrice: 1524,
      image: "/images/unit/indoor & outdoor /INDOOR_CS-TZ50ZKEW.png",
      outdoorImage: "/images/unit/indoor & outdoor /OUTDOOR_CU-TZ50ZKE.png",
      energyRating: "A++",
      features: ["5.0kW cooling capacity", "5.8kW heating capacity", "Energy rating A++", "Super quiet operation", "Built-in WiFi"],
    },
    {
      id: "tz-71",
      model: "Panasonic TZ Compact 7.1kW",
      type: "Wall Mounted",
      cooling: 7.1,
      heating: 8.2,
      listPrice: 2272,
      discountedPrice: 1318,
      installationCost: 600,
      finalPrice: 1918,
      image: "/images/unit/indoor & outdoor /INDOOR_CS-TZ71ZKEW.png",
      outdoorImage: "/images/unit/indoor & outdoor /OUTDOOR_CU-TZ71ZKE.png",
      energyRating: "A++",
      features: ["7.1kW cooling capacity", "8.2kW heating capacity", "Energy rating A++", "Super quiet operation", "Built-in WiFi"],
    },

    // Z Series (Premium Etherea Range)
    {
      id: "etherea-25",
      model: "Panasonic Etherea 2.5kW",
      type: "Wall Mounted",
      cooling: 2.5,
      heating: 3.4,
      listPrice: 1351,
      discountedPrice: 784,
      installationCost: 600,
      finalPrice: 1384,
      image: "/images/unit/indoor & outdoor /INDOOR_CS-Z25ZKEW.png",
      outdoorImage: "/images/unit/indoor & outdoor /OUTDOOR_CU-Z25ZKE.png",
      energyRating: "A+++",
      features: ["2.5kW cooling capacity", "3.4kW heating capacity", "Energy rating A+++", "nanoe™ X air purification", "Premium design"],
    },
    {
      id: "etherea-35",
      model: "Panasonic Etherea 3.5kW",
      type: "Wall Mounted",
      cooling: 3.5,
      heating: 4.0,
      listPrice: 1452,
      discountedPrice: 842,
      installationCost: 600,
      finalPrice: 1442,
      image: "/images/unit/indoor & outdoor /INDOOR_CS-Z35ZKEW.png",
      outdoorImage: "/images/unit/indoor & outdoor /OUTDOOR_CU-Z35ZKE.png",
      energyRating: "A+++",
      features: ["3.5kW cooling capacity", "4.0kW heating capacity", "Energy rating A+++", "nanoe™ X air purification", "Premium design"],
    },
    {
      id: "etherea-50",
      model: "Panasonic Etherea 5.0kW",
      type: "Wall Mounted",
      cooling: 5.0,
      heating: 5.8,
      listPrice: 1793,
      discountedPrice: 1040,
      installationCost: 600,
      finalPrice: 1640,
      image: "/images/unit/indoor & outdoor /INDOOR_CS-Z50ZKEW.png",
      outdoorImage: "/images/unit/indoor & outdoor /OUTDOOR_CU-Z50ZKE.png",
      energyRating: "A+++",
      features: ["5.0kW cooling capacity", "5.8kW heating capacity", "Energy rating A+++", "nanoe™ X air purification", "Premium design"],
    },
  ];

  // Multi-split systems data
  const multiSplitSystems = {
    outdoorUnits: [
      {
        id: "cu-2z35",
        model: "CU-2Z35TBE",
        maxRooms: 2,
        cooling: 3.5,
        heating: 4.5,
        image: "/images/unit/indoor & outdoor /OUTDOOR_CU-2Z35TBE.png",
        price: 1200,
      },
      {
        id: "cu-2z50",
        model: "CU-2Z50TBE",
        maxRooms: 2,
        cooling: 5.0,
        heating: 6.8,
        image: "/images/unit/indoor & outdoor /OUTDOOR_CU-2Z50TBE.png",
        price: 1400,
      },
      {
        id: "cu-3z52",
        model: "CU-3Z52TBE",
        maxRooms: 3,
        cooling: 5.2,
        heating: 6.8,
        image: "/images/unit/indoor & outdoor /OUTDOOR_CU-3Z52TBE.png",
        price: 1800,
      },
      {
        id: "cu-4z80",
        model: "CU-4Z80TBE",
        maxRooms: 4,
        cooling: 8.0,
        heating: 9.4,
        image: "/images/unit/indoor & outdoor /OUTDOOR_CU-4Z80TBE.png",
        price: 2200,
      },
    ],
    indoorUnits: {
      basic: {
        model: "TZ Compact Series",
        variants: [
          {
            id: "cs-tz25",
            model: "CS-TZ25ZKEW",
            cooling: 2.5,
            heating: 3.3,
            image: "/images/unit/indoor & outdoor /INDOOR_CS-TZ25ZKEW.png",
            price: 400,
          },
          {
            id: "cs-tz35",
            model: "CS-TZ35ZKEW",
            cooling: 3.5,
            heating: 4.0,
            image: "/images/unit/indoor & outdoor /INDOOR_CS-TZ35ZKEW.png",
            price: 450,
          },
          {
            id: "cs-tz50",
            model: "CS-TZ50ZKEW",
            cooling: 5.0,
            heating: 5.8,
            image: "/images/unit/indoor & outdoor /INDOOR_CS-TZ50ZKEW.png",
            price: 550,
          },
        ],
      },
      premium: {
        model: "Etherea Z Series",
        variants: [
          {
            id: "cs-z25",
            model: "CS-Z25ZKEW",
            cooling: 2.5,
            heating: 3.4,
            image: "/images/unit/indoor & outdoor /INDOOR_CS-Z25ZKEW.png",
            price: 500,
          },
          {
            id: "cs-z35",
            model: "CS-Z35ZKEW",
            cooling: 3.5,
            heating: 4.0,
            image: "/images/unit/indoor & outdoor /INDOOR_CS-Z35ZKEW.png",
            price: 550,
          },
          {
            id: "cs-z50",
            model: "CS-Z50ZKEW",
            cooling: 5.0,
            heating: 5.8,
            image: "/images/unit/indoor & outdoor /INDOOR_CS-Z50ZKEW.png",
            price: 650,
          },
        ],
      },
    },
  };

  // Remove a room from the quote
  const removeRoom = (id) => {
    setRooms(rooms.filter((room) => room.id !== id));
  };

  // Update room details
  const updateRoom = (id, field, value) => {
    setRooms(
      rooms.map((room) => {
        if (room.id === id) {
          const updatedRoom = { ...room, [field]: value };

          // If size is updated, update the cooling requirement
          if (field === "size" && value) {
            updatedRoom.cooling = roomSizes[value].cooling;
          }

          return updatedRoom;
        }
        return room;
      }),
    );
  };

  // Handle contact info changes
  const handleContactChange = (e) => {
    const { name, value } = e.target;
    setContactInfo({ ...contactInfo, [name]: value });
  };

  // Navigate to next step
  const nextStep = () => {
    setStep(step + 1);
    window.scrollTo(0, 0);
  };

  // Navigate to previous step
  const prevStep = () => {
    setStep(step - 1);
    window.scrollTo(0, 0);
  };

  // Find the best single split unit for a given cooling capacity and type
  const findBestSingleSplitUnit = (cooling, unitType = "basic") => {
    // Filter units by type
    const typeUnits =
      unitType === "premium"
        ? singleSplitSystems.filter((unit) => unit.model.includes("Etherea"))
        : singleSplitSystems.filter((unit) => unit.model.includes("TZ"));

    // Find the unit with cooling capacity >= required cooling
    const eligibleUnits = typeUnits.filter((unit) => unit.cooling >= cooling);

    if (eligibleUnits.length === 0) {
      // If no unit is large enough, return the largest available
      return typeUnits.reduce((prev, current) => (prev.cooling > current.cooling ? prev : current));
    }

    // Return the unit with cooling capacity closest to the requirement
    return eligibleUnits.reduce((prev, current) =>
      Math.abs(current.cooling - cooling) < Math.abs(prev.cooling - cooling) ? current : prev,
    );
  };

  // Find the best indoor unit for a given cooling capacity and type
  const findBestIndoorUnit = (cooling, unitType = "basic") => {
    // Filter units by type
    const typeUnits =
      unitType === "premium"
        ? multiSplitSystems.indoorUnits.basic.variants.filter((unit) => unit.cooling >= cooling)
        : multiSplitSystems.indoorUnits.basic.variants.filter((unit) => unit.cooling >= cooling);

    if (typeUnits.length === 0) {
      // If no unit is large enough, return the largest available
      return multiSplitSystems.indoorUnits.basic.variants.reduce((prev, current) => (prev.cooling > current.cooling ? prev : current));
    }

    // Return the unit with cooling capacity closest to the requirement
    return typeUnits.reduce((prev, current) =>
      Math.abs(current.cooling - cooling) < Math.abs(prev.cooling - cooling) ? current : prev,
    );
  };

  // Find the best outdoor unit for the selected rooms
  const findBestOutdoorUnit = (rooms) => {
    // Calculate total cooling required
    const totalCooling = rooms.reduce((sum, room) => sum + room.cooling, 0);

    // Find eligible outdoor units that can support the number of rooms and total cooling
    const eligibleOutdoorUnits = multiSplitSystems.outdoorUnits.filter(
      (unit) => unit.maxRooms >= rooms.length && unit.cooling >= totalCooling,
    );

    // Select the outdoor unit with the smallest sufficient capacity
    return eligibleOutdoorUnits.length > 0
      ? eligibleOutdoorUnits.reduce((prev, current) => (current.cooling < prev.cooling ? current : prev))
      : multiSplitSystems.outdoorUnits.reduce((prev, current) =>
          current.cooling > prev.cooling || current.maxRooms > prev.maxRooms ? current : prev,
        );
  };

  // Generate the quote based on selected rooms
  const generateQuote = () => {
    // Calculate total cooling capacity needed
    const totalCooling = rooms.reduce((sum, room) => sum + room.cooling, 0);

    // Initialize selectedUnitTypes with default value if empty
    if (!selectedUnitTypes || selectedUnitTypes.length === 0) {
      setSelectedUnitTypes(Array(rooms.length).fill('basic'));
    }

    // Calculate total pipework needed
    const totalPipework = rooms.length === 1 ? 6 : 6 * rooms.length;

    // Determine if we need single or multi-split system
    if (rooms.length === 1) {
      // For single room, find the closest matching single split system
      const room = rooms[0];

      // Get basic and premium options
      const basicOption = findBestSingleSplitUnit(room.cooling, "basic");
      const premiumOption = findBestSingleSplitUnit(room.cooling, "premium");

      const quoteData = {
        type: "single",
        totalCooling,
        rooms,
        options: {
          basic: basicOption,
          premium: premiumOption,
        },
        installationCost: 600,
        totalPipework,
      };

      setQuoteDetails(quoteData);
    } else {
      // For multiple rooms, find the best outdoor unit and matching indoor units
      const outdoorUnit = findBestOutdoorUnit(rooms);
      const roomOptions = rooms.map((room) => ({
        room,
        options: {
          basic: findBestIndoorUnit(room.cooling, "basic"),
          premium: findBestIndoorUnit(room.cooling, "premium"),
        },
      }));

      const quoteData = {
        type: "multi",
        totalCooling,
        rooms,
        outdoorUnit,
        roomOptions,
        installationCost: 600 + 450 * (rooms.length - 1),
        totalPipework,
      };

      setQuoteDetails(quoteData);
    }
    
    nextStep();
  };

  const handleQuoteEmail = async (quoteData) => {
    if (!quoteData) {
      console.error('Missing quote data');
      setToast({
        show: true,
        message: 'Quote details are missing. Please try again.',
        type: 'error'
      });
      return false;
    }

    if (!selectedUnitTypes || selectedUnitTypes.length === 0) {
      console.error('No unit types selected');
      setToast({
        show: true,
        message: 'Please select a system type before proceeding.',
        type: 'error'
      });
      return false;
    }

    if (!rooms || rooms.length === 0) {
      console.error('No rooms selected');
      setToast({
        show: true,
        message: 'Please add at least one room before proceeding.',
        type: 'error'
      });
      return false;
    }

    const clientDetails = {
      name: contactInfo.name,
      email: contactInfo.email,
      phone: contactInfo.phone,
      address: contactInfo.address,
      postcode: contactInfo.postcode,
      fullAddress: `${contactInfo.address || ''} ${contactInfo.postcode || ''}`.trim()
    };

    const installationInfo = {
      fGasCertified: "Professional installation by certified experts",
      niceicApproved: "Professional electrical installation by certified experts",
      pipework: "Up to 3 meters of pipework included",
      wallBracket: "Wall bracket for outdoor unit included",
      warranty: "5-year warranty on parts and labour",
      support: "Dedicated technical support team"
    };

    const unitDetails = selectedSystemType === 'single-split' ? {
      indoorUnit: {
        model: selectedUnitTypes[0] === 'premium' ? 'CS-Z25ZKEW' : 'CS-TZ25ZKEW',
        cooling: roomSizes[rooms[0].size].cooling,
        heating: roomSizes[rooms[0].size].heating,
        energyRating: selectedUnitTypes[0] === 'premium' ? 'A+++' : 'A++',
        features: selectedUnitTypes[0] === 'premium' ? [
          'nanoe™X air purification',
          'Built-in Wi-Fi',
          'Super Quiet Mode - 19dB(A)',
          'Aerowings Air Flow'
        ] : [
          'PM2.5 Filter',
          'Super Quiet Mode - 20dB(A)',
          'Aerowings Air Flow'
        ]
      },
      outdoorUnit: {
        model: selectedUnitTypes[0] === 'premium' ? 'CU-Z25ZKE' : 'CU-TZ25ZKE',
        cooling: roomSizes[rooms[0].size].cooling,
        heating: roomSizes[rooms[0].size].heating,
        maxRooms: 1,
        fullModelDetails: selectedUnitTypes[0] === 'premium' 
          ? 'Panasonic Z Series High Efficiency Outdoor Unit'
          : 'Panasonic TZ Series Standard Outdoor Unit'
      }
    } : {
      indoorUnits: rooms.map((room, _index) => ({
        room: room.name,
        model: selectedUnitTypes[_index] === 'premium' ? 'CS-Z25ZKEW' : 'CS-TZ25ZKEW',
        cooling: roomSizes[room.size].cooling,
        heating: roomSizes[room.size].heating,
        fullModelDetails: selectedUnitTypes[_index] === 'premium'
          ? 'Panasonic Etherea Z Series Wall Mounted Unit'
          : 'Panasonic TZ Compact Series Wall Mounted Unit'
      })),
      outdoorUnit: {
        model: 'CU-2Z50TBE',
        cooling: rooms.reduce((sum, room) => sum + roomSizes[room.size].cooling, 0),
        heating: rooms.reduce((sum, room) => sum + roomSizes[room.size].heating, 0),
        maxRooms: rooms.length,
        fullModelDetails: `Panasonic Multi-Split Outdoor Unit (${rooms.length} Room System)`
      }
    };

    const quoteDetails = {
      type: 'Air Conditioning Installation',
      systemType: selectedUnitTypes[0] === 'premium' ? 'Premium Range (Etherea)' : 'Basic Range (TZ Series)',
      rooms: rooms.map(room => ({
        name: room.name,
        size: room.size,
        count: 1,
        kw: roomSizes[room.size].cooling,
        cooling: roomSizes[room.size].cooling,
        heating: roomSizes[room.size].heating
      })),
      totalCost: totalCost,
      installationInfo,
      unitDetails,
      totalUnits: rooms.length,
      totalSystemCapacity: rooms.reduce((sum, room) => sum + roomSizes[room.size].cooling, 0)
    };

    try {
      console.log('Sending quote email with data:', {
        clientDetails,
        quoteDetails
      });

      await sendQuoteEmail(clientDetails, quoteDetails);
      console.log('Quote email sent successfully');
      setToast({
        show: true,
        message: 'Quote sent successfully! Please check your email.',
        type: 'success'
      });
      return true;
    } catch (error) {
      console.error('Error sending quote email:', error);
      setToast({
        show: true,
        message: `There was an error sending your quote. Please try again or contact us at info@bre-services.com`,
        type: 'error'
      });
      return false;
    }
  };

  // Format price as GBP
  const formatPrice = (price) => {
    if (price === undefined || price === null) return '£0';
    return `£${price.toLocaleString("en-GB")}`;
  };

  // Check if current step is valid to proceed
  const isStepValid = () => {
    switch (step) {
      case 1: // Property type
        return propertyType !== "";
      case 2: // Room selection
        return rooms.length > 0 && rooms.every((room) => room.size !== "");
      case 3: // Contact info
        return contactInfo.name && contactInfo.email && contactInfo.phone && contactInfo.postcode;
      case 4: // System type selection
        return selectedUnitTypes.length > 0 && selectedUnitTypes[0] !== undefined;
      default:
        return true;
    }
  };

  // Calculate estimated cost whenever rooms change
  useEffect(() => {
    const calculateEstimatedCost = () => {
      if (rooms.length === 0) return;
      
      // Base installation cost for first unit
      const firstUnitInstallationCost = 400;
      
      // Additional unit installation cost
      const additionalUnitInstallationCost = 350;
      
      // Base material cost per system
      const baseMaterialCost = 200;
      
      // Additional material costs for larger units
      const additionalMaterialCosts = {
        large: 365,      // 5.0kW
        extraLarge: 465  // 7.1kW
      };
      
      // List prices for each room size and range
      const listPrices = {
        basic: {
          small: 1083,    // 2.5kW
          medium: 1252,   // 3.5kW
          large: 1593,    // 5.0kW
          extraLarge: 2272 // 7.1kW
        },
        premium: {
          small: 1351,    // 2.5kW
          medium: 1452,   // 3.5kW
          large: 1793,    // 5.0kW
          extraLarge: 2400 // 7.1kW
        }
      };
      
      // Calculate total cost for each room
      const totalCost = rooms.reduce((acc, room) => {
        if (room.size) {
          const range = selectedUnitTypes[0] === 'premium' ? 'premium' : 'basic';
          // Apply 35% discount to list price
          const discountedPrice = listPrices[range][room.size] * 0.65;
          return acc + discountedPrice;
        }
        return acc;
      }, 0);
      
      // Calculate installation costs
      const installationCost = firstUnitInstallationCost + 
        (additionalUnitInstallationCost * (rooms.length - 1));
      
      // Calculate material costs including additional costs for larger units
      const totalMaterialCost = rooms.reduce((acc, room) => {
        if (room.size) {
          let materialCost = baseMaterialCost;
          if (room.size === 'large') {
            materialCost += additionalMaterialCosts.large;
          } else if (room.size === 'extraLarge') {
            materialCost += additionalMaterialCosts.extraLarge;
          }
          return acc + materialCost;
        }
        return acc;
      }, 0);
      
      // Calculate total system cost
      const systemCost = totalCost + installationCost + totalMaterialCost;
      
      return systemCost;
    };

    const newTotal = calculateEstimatedCost();
    setTotalCost(newTotal);
  }, [rooms, roomSizes, selectedUnitTypes]);

  // Initialize selectedUnitTypes when rooms change
  useEffect(() => {
    if (rooms.length > 0 && (!selectedUnitTypes || selectedUnitTypes.length === 0)) {
      setSelectedUnitTypes(Array(rooms.length).fill('basic'));
    }
  }, [rooms.length, selectedUnitTypes]);

  const handleUnitTypeSelection = (type) => {
    console.log('Setting unit type:', type);
    const newTypes = Array(rooms.length).fill(type);
    console.log('New types array:', newTypes);
    setSelectedUnitTypes(newTypes);
    setSelectedSystemType(type === 'premium' ? 'Premium' : 'Basic');
  };

  const handleFinalizeQuote = async () => {
    // Ensure selectedUnitTypes is initialized
    if (!selectedUnitTypes || selectedUnitTypes.length === 0) {
      setSelectedUnitTypes(Array(rooms.length).fill('basic'));
    }

    if (!quoteDetails) {
      console.error('No quote details available');
      setToast({ show: true, message: 'Quote details are missing. Please try again.' });
      return;
    }

    // Update quote details with current total cost
    const updatedQuoteDetails = {
      ...quoteDetails,
      totalCost: totalCost,
      systemType: selectedUnitTypes[0] === 'premium' ? 'Premium Range (Etherea)' : 'Basic Range (TZ Series)'
    };

    // Progress to next step immediately
    nextStep();

    // Attempt to send email in background
    try {
      const emailSent = await handleQuoteEmail(updatedQuoteDetails);
      if (!emailSent) {
        console.log('Email sending failed but step progression continued');
      }
    } catch (error) {
      console.error('Error sending email:', error);
    }
  };

  const renderStep4 = () => {
    if (!rooms.length) return null;

    return (
      <div className="quote-step">
        <h3>Select Your Panasonic System Type</h3>
        <p className="brand-description">All our systems are genuine Panasonic air conditioning units, offering industry-leading quality and reliability.</p>
        <div className="system-type-options">
          <div 
            className={`system-type-button ${selectedUnitTypes[0] === 'basic' ? 'active' : ''}`}
            onClick={() => handleUnitTypeSelection('basic')}
          >
            <div className="brand-badge">Panasonic</div>
            <div className="unit-preview">
              <div className="indoor-unit">
                <h4>Panasonic TZ Series Indoor Unit</h4>
                <img 
                  src="/images/unit/indoor & outdoor /INDOOR_CS-TZ35ZKEW.png" 
                  alt="Panasonic TZ Series Indoor Unit"
                />
              </div>
            </div>
            <h4>Panasonic TZ Series (Basic Range)</h4>
            <ul className="features-list">
              <li>Energy rating A++</li>
              <li>Super quiet operation</li>
              <li>Built-in WiFi</li>
              <li>Standard air purification</li>
              <li>5-year Panasonic warranty</li>
            </ul>
            <p className="price-note">Best value Panasonic system</p>
          </div>

          <div 
            className={`system-type-button ${selectedUnitTypes[0] === 'premium' ? 'active' : ''}`}
            onClick={() => handleUnitTypeSelection('premium')}
          >
            <div className="brand-badge premium">Panasonic</div>
            <div className="unit-preview">
              <div className="indoor-unit">
                <h4>Panasonic Etherea Series Indoor Unit</h4>
                <img 
                  src="/images/unit/indoor & outdoor /INDOOR_CS-Z35ZKEW.png" 
                  alt="Panasonic Etherea Series Indoor Unit"
                />
              </div>
            </div>
            <h4>Panasonic Etherea Series (Premium Range)</h4>
            <ul className="features-list">
              <li>Energy rating A+++</li>
              <li>Ultra quiet operation</li>
              <li>Built-in WiFi</li>
              <li>nanoe™ X air purification</li>
              <li>Premium design</li>
              <li>5-year Panasonic warranty</li>
            </ul>
            <p className="price-note">Premium Panasonic features & efficiency</p>
          </div>
        </div>

        <div className="navigation-buttons">
          <button 
            className="secondary-button" 
            onClick={prevStep}
          >
            Back
          </button>
          <button 
            className="primary-button" 
            onClick={handleFinalizeQuote}
            disabled={!selectedUnitTypes.length || !selectedUnitTypes[0]}
          >
            Continue
          </button>
        </div>
      </div>
    );
  };

  // Add useEffect to handle toast timeout
  useEffect(() => {
    if (toast.show) {
      const timer = setTimeout(() => {
        setToast({ show: false, message: '' });
      }, 3000); // Hide toast after 3 seconds
      return () => clearTimeout(timer);
    }
  }, [toast.show]);

  return (
    <div className="quote-calculator">
      <style jsx>{`
        .system-type-selection {
          max-width: 1200px;
          margin: 0 auto;
          padding: 2rem;
        }

        .system-type-selection h2 {
          text-align: center;
          margin-bottom: 0.5rem;
          color: #1a202c;
        }

        .system-type-selection p {
          text-align: center;
          color: #4a5568;
          margin-bottom: 2rem;
        }

        .system-type-options {
          display: grid;
          grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
          gap: 2rem;
          margin-bottom: 2rem;
        }

        .system-type-button {
          display: flex;
          flex-direction: column;
          align-items: center;
          padding: 2rem;
          border: 2px solid #e2e8f0;
          border-radius: 12px;
          background: white;
          transition: all 0.3s ease;
          cursor: pointer;
          width: 100%;
          gap: 1.5rem;
        }

        .system-type-button:hover {
          transform: translateY(-4px);
          box-shadow: 0 4px 6px -1px rgba(0, 0, 0, 0.1);
        }

        .system-type-button.active {
          border-color: #3b82f6;
          background-color: #f8fafc;
        }

        .system-type-button h4 {
          color: #1a202c;
          margin: 1rem 0;
          font-size: 1.25rem;
          text-align: center;
        }

        .unit-preview {
          width: 100%;
          margin-bottom: 1.5rem;
        }

        .indoor-unit, .outdoor-unit {
          text-align: center;
          padding: 1.5rem;
          background: #f8fafc;
          border-radius: 8px;
          margin-bottom: 1rem;
        }

        .indoor-unit img, .outdoor-unit img {
          width: 100%;
          height: 180px;
          object-fit: contain;
          margin: 1rem 0;
        }

        .model-info {
          text-align: center;
          margin-top: 0.5rem;
        }

        .model-name {
          font-size: 1rem;
          color: #2d3748;
          margin-bottom: 0.25rem;
          font-weight: 500;
        }

        .model-number {
          font-size: 0.875rem;
          color: #4a5568;
          font-family: monospace;
        }

        .features-list {
          width: 100%;
          margin: 0;
          padding: 1.5rem;
          list-style: none;
          background-color: #f8fafc;
          border-radius: 8px;
        }

        .features-list li {
          color: #4a5568;
          margin-bottom: 0.75rem;
          font-size: 0.95rem;
          text-align: center;
          position: relative;
          padding-left: 1.5rem;
        }

        .features-list li:last-child {
          margin-bottom: 0;
        }

        .features-list li::before {
          content: "✓";
          color: #10b981;
          font-weight: bold;
          font-size: 1rem;
          position: absolute;
          left: 0;
          top: 50%;
          transform: translateY(-50%);
        }

        .navigation-buttons {
          display: flex;
          justify-content: space-between;
          margin-top: 2rem;
          padding: 0 1rem;
        }

        .secondary-button, .primary-button {
          padding: 0.75rem 1.5rem;
          border-radius: 6px;
          font-weight: 500;
          transition: all 0.3s ease;
        }

        .secondary-button {
          background-color: #e2e8f0;
          color: #4a5568;
        }

        .secondary-button:hover {
          background-color: #cbd5e1;
        }

        .primary-button {
          background-color: #3b82f6;
          color: white;
        }

        .primary-button:hover:not(:disabled) {
          background-color: #2563eb;
        }

        .primary-button:disabled {
          background-color: #cbd5e1;
          cursor: not-allowed;
        }
      `}</style>
      {/* Step indicator */}
      <div className="step-indicator">
        {(steps || []).map(s => (
          <div key={s.id} className={`step ${step === s.id ? 'active' : ''} ${step > s.id ? 'completed' : ''}`}>
            <div className="step-number">
              {step > s.id ? <CheckCircle className="h-4 w-4" /> : s.id}
            </div>
            <div className="step-label">{s.name}</div>
          </div>
        ))}
        <div className="step-line"></div>
        <div className="step-progress" style={{ width: `${((step - 1) / ((steps || []).length - 1)) * 100}%` }}></div>
      </div>

      {/* Step 1: Property Type */}
      {step === 1 && (
        <div className="property-type-section">
          <h1>Which of these best describes your property?</h1>
          <div className="property-type-grid">
            {(propertyTypes || []).map((type) => (
              <div
                key={type.id}
                className={`property-type-card ${propertyType === type.id ? 'selected' : ''}`}
                onClick={() => {
                  setPropertyType(type.id);
                  nextStep();
                }}
                onMouseEnter={() => setShowTooltip(type.id)}
                onMouseLeave={() => setShowTooltip(null)}
              >
                <img
                  src={type.image}
                  alt={type.name}
                  className="property-type-image"
                />
                <h3>{type.name}</h3>
                <p>{type.description}</p>
                {showTooltip === type.id && (
                  <div className="tooltip">
                    <Info className="tooltip-icon" />
                    {type.tooltip}
                  </div>
                )}
              </div>
            ))}
          </div>
          <div className="navigation-buttons">
            <button
              className="primary-button"
              onClick={nextStep}
              disabled={!isStepValid()}
            >
              Next
            </button>
          </div>
        </div>
      )}

      {/* Step 2: Room Selection */}
      {step === 2 && (
        <div className="room-section">
          <h1>Which rooms need air conditioning?</h1>
          {/* Add toast notification */}
          {toast.show && (
            <div className="toast-notification">
              <CheckCircle className="toast-icon" />
              {toast.message}
            </div>
          )}
          <div className="room-grid">
            {(roomTypeOptions || []).map((roomType) => (
              <div
                key={roomType.id}
                className="room-card"
                onClick={() => {
                  const newRoom = {
                    id: Date.now(),
                    name: roomType.name,
                    type: roomType.id,
                    size: "",
                    cooling: 0,
                  };
                  setRooms([...rooms, newRoom]);
                  // Show toast notification
                  setToast({ show: true, message: `${roomType.name} added successfully!` });
                }}
                onMouseEnter={() => setShowTooltip(roomType.id)}
                onMouseLeave={() => setShowTooltip(null)}
              >
                <img src={roomType.image} alt={roomType.name} />
                <span>{roomType.name}</span>
                {showTooltip === roomType.id && (
                  <div className="tooltip">
                    <Info className="tooltip-icon" />
                    {roomType.tooltip}
                  </div>
                )}
              </div>
            ))}
          </div>

          {rooms.length > 0 && (
            <div className="selected-rooms">
              <div className="selected-rooms-header">
                <h2>Selected Rooms</h2>
              </div>
              <div className="selected-rooms-list">
                {(rooms || []).map((room, _index) => (
                  <div key={_index} className="selected-room-item">
                    <span>{room.name}</span>
                    <div>
                      <div className="size-select-container">
                        <select
                          className="room-size-select"
                          value={room.size}
                          onChange={(e) => updateRoom(room.id, "size", e.target.value)}
                          onMouseEnter={() => setShowTooltip(`size-${room.id}`)}
                          onMouseLeave={() => setShowTooltip(null)}
                        >
                          <option value="">Select Size</option>
                          {(Object.entries(roomSizes) || []).map(([key, size]) => (
                            <option key={key} value={key}>
                              {size.name} ({size.area})
                            </option>
                          ))}
                        </select>
                        {showTooltip === `size-${room.id}` && room.size && (
                          <div className="tooltip size-tooltip">
                            <Info className="tooltip-icon" />
                            {roomSizes[room.size].tooltip}
                          </div>
                        )}
                      </div>
                      <button
                        className="remove-room-btn"
                        onClick={(e) => {
                          e.stopPropagation();
                          removeRoom(room.id);
                        }}
                      >
                        Remove
                      </button>
                    </div>
                  </div>
                ))}
              </div>
            </div>
          )}

          <div className="navigation-buttons">
            <button className="secondary-button" onClick={prevStep}>
              Back
            </button>
            <button
              className="primary-button"
              onClick={nextStep}
              disabled={!isStepValid()}
            >
              Next
            </button>
          </div>
        </div>
      )}

      {/* Step 3: Contact Information */}
      {step === 3 && (
        <div>
          <h1>Your Contact Details</h1>
          <div className="contact-form">
            <div className="form-group">
              <label htmlFor="name">Name</label>
              <input
                type="text"
                id="name"
                name="name"
                value={contactInfo.name}
                onChange={handleContactChange}
                placeholder="Your name"
                required
              />
            </div>
            <div className="form-group">
              <label htmlFor="email">Email</label>
              <input
                type="email"
                id="email"
                name="email"
                value={contactInfo.email}
                onChange={handleContactChange}
                placeholder="your.email@example.com"
                required
              />
            </div>
            <div className="form-group">
              <label htmlFor="phone">Phone Number</label>
              <input
                type="tel"
                id="phone"
                name="phone"
                value={contactInfo.phone}
                onChange={handleContactChange}
                placeholder="07123 456789"
                required
              />
            </div>
            <div className="form-group">
              <label htmlFor="postcode">Postcode</label>
              <input
                type="text"
                id="postcode"
                name="postcode"
                value={contactInfo.postcode}
                onChange={handleContactChange}
                placeholder="IP1 1AA"
                required
              />
            </div>
          </div>

          <div className="navigation-buttons">
            <button className="secondary-button" onClick={prevStep}>
              Back
            </button>
            <button
              className="primary-button"
              onClick={generateQuote}
              disabled={!isStepValid()}
            >
              Get Your Quote
            </button>
          </div>
        </div>
      )}

      {/* Step 4: System Type Selection */}
      {step === 4 && quoteDetails && renderStep4()}

      {/* Step 5: Quote Results */}
      {step === 5 && quoteDetails && (
        <div className="quote-result">
          <div className="quote-header">
            <h1>Your Quote Summary</h1>
            <p>Here's your personalised air conditioning solution based on your requirements.</p>
            <div className="total-cost">
              <h2>Total Cost (inc. VAT)</h2>
              <div className="price">{formatPrice(totalCost)}</div>
            </div>
          </div>
          
          <div className="quote-options">
            {quoteDetails.type === "single" ? (
              <div className="quote-option-card">
                <div className="option-header">
                  <h2>{selectedUnitTypes[0] === "premium" ? "Premium Etherea System" : "Basic TZ System"}</h2>
                </div>
                <div className="unit-card">
                  <div className="unit-preview">
                    <div className="indoor-unit">
                      <h4>Indoor Unit</h4>
                      <img
                        src={selectedUnitTypes[0] === "premium" 
                          ? "/images/unit/indoor & outdoor /INDOOR_CS-Z35ZKEW.png"
                          : "/images/unit/indoor & outdoor /INDOOR_CS-TZ35ZKEW.png"}
                        alt="Indoor Unit"
                        className="unit-image"
                      />
                    </div>
                    <div className="outdoor-unit">
                      <h4>Outdoor Unit</h4>
                      <img
                        src={selectedUnitTypes[0] === "premium" 
                          ? "/images/unit/indoor & outdoor /OUTDOOR_CU-Z35ZKE.png"
                          : "/images/unit/indoor & outdoor /OUTDOOR_CU-TZ35ZKE.png"}
                        alt="Outdoor Unit"
                        className="unit-image"
                      />
                    </div>
                  </div>
                  <div className="unit-info">
                    <h4>{selectedUnitTypes[0] === "premium" 
                      ? "Panasonic Etherea Z Series"
                      : "Panasonic TZ Compact Series"}</h4>
                    <div className="unit-specs">
                      <div className="unit-spec">
                        <span>Cooling:</span> {selectedUnitTypes[0] === "premium" 
                          ? quoteDetails.options.premium.cooling 
                          : quoteDetails.options.basic.cooling}kW
                      </div>
                      <div className="unit-spec">
                        <span>Heating:</span> {selectedUnitTypes[0] === "premium" 
                          ? quoteDetails.options.premium.heating 
                          : quoteDetails.options.basic.heating}kW
                      </div>
                      <div className="unit-spec">
                        <span>Energy Rating:</span> {selectedUnitTypes[0] === "premium" 
                          ? "A+++" 
                          : "A++"}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            ) : (
              <div className="quote-option-card">
                <div className="option-header">
                  <h2>{selectedUnitTypes[0] === "premium" ? "Premium Multi-Split System" : "Basic Multi-Split System"}</h2>
                </div>
                <div className="multi-system-details">
                  <div className="outdoor-unit">
                    <h3>Outdoor Unit</h3>
                    <img
                      src={quoteDetails.outdoorUnit.image}
                      alt={quoteDetails.outdoorUnit.model}
                      className="unit-image"
                    />
                    <div className="unit-info">
                      <h4>{quoteDetails.outdoorUnit.model}</h4>
                      <div className="unit-specs">
                        <div className="unit-spec">
                          <span>System Capacity:</span>
                        </div>
                        <div className="unit-spec">
                          <span>Cooling:</span> {quoteDetails.outdoorUnit.cooling}kW
                        </div>
                        <div className="unit-spec">
                          <span>Heating:</span> {quoteDetails.outdoorUnit.heating}kW
                        </div>
                        <div className="unit-spec">
                          <span>Max Rooms:</span> {quoteDetails.outdoorUnit.maxRooms}
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className="indoor-units">
                    <h3>Indoor Units</h3>
                    {rooms.map((room, _index) => (
                      <div key={_index} className="indoor-unit">
                        <h4>{room.name}</h4>
                        <img
                          src={selectedUnitTypes[_index] === "premium"
                            ? "/images/unit/indoor & outdoor /INDOOR_CS-Z35ZKEW.png"
                            : "/images/unit/indoor & outdoor /INDOOR_CS-TZ35ZKEW.png"}
                          alt="Indoor Unit"
                          className="unit-image"
                        />
                        <div className="unit-info">
                          <h4>{selectedUnitTypes[_index] === "premium"
                            ? "Panasonic Etherea Z Series"
                            : "Panasonic TZ Compact Series"}</h4>
                          <div className="unit-specs">
                            <div className="unit-spec">
                              <span>Room Size:</span> {roomSizes[room.size].name}
                            </div>
                            <div className="unit-spec">
                              <span>System Capacity:</span>
                            </div>
                            <div className="unit-spec">
                              <span>Cooling:</span> {roomSizes[room.size].cooling}kW
                            </div>
                            <div className="unit-spec">
                              <span>Heating:</span> {selectedUnitTypes[_index] === "premium" 
                                ? quoteDetails.roomOptions[_index].options.premium.heating 
                                : quoteDetails.roomOptions[_index].options.basic.heating}kW
                            </div>
                          </div>
                        </div>
                      </div>
                    ))}
                  </div>
                </div>
              </div>
            )}
          </div>

          <div className="installation-info">
            <h3>Professional Installation Included</h3>
            <ul>
              <li>
                <strong>F-Gas Certified Engineers</strong>
                <span>Professional installation by certified experts</span>
              </li>
              <li>
                <strong>NICEIC Approved Contractor</strong>
                <span>Professional electrical installation by certified experts</span>
              </li>
              <li>
                <strong>All necessary pipework</strong>
                <span>Up to {quoteDetails.totalPipework} metres included</span>
              </li>
              <li>
                <strong>Wall bracket</strong>
                <span>For secure outdoor unit mounting</span>
              </li>
              <li>
                <strong>5-year warranty</strong>
                <span>On parts and labour</span>
              </li>
              <li>
                <strong>After-sales support</strong>
                <span>Dedicated technical support team</span>
              </li>
            </ul>
          </div>

          <div className="navigation-buttons">
            <button className="secondary-button" onClick={prevStep}>
              Back
            </button>
            <button
              className="primary-button"
              onClick={() => handleQuoteEmail(quoteDetails)}
            >
              Email Quote
            </button>
          </div>
        </div>
      )}
    </div>
  );
};

export default AirConQuoteCalculator; 