export const locations = {
  ipswich: {
    name: "Ipswich",
    coordinates: {
      latitude: 52.0594,
      longitude: 1.1556
    },
    services: [
      {
        name: "Electrical Services",
        description: "Professional electrical services for homes and businesses in Ipswich",
        features: [
          "Full house rewiring",
          "Electrical safety inspections",
          "Emergency call-outs",
          "EV charging point installation",
          "Commercial electrical installations"
        ]
      },
      {
        name: "Air Conditioning",
        description: "Expert air conditioning installation and maintenance in Ipswich",
        features: [
          "Domestic and commercial installations",
          "Regular maintenance plans",
          "Emergency repairs",
          "Energy-efficient solutions",
          "F-Gas registered engineers"
        ]
      }
    ],
    testimonials: [
      {
        rating: 5,
        text: "Excellent service from start to finish. The team was professional and completed the work to a high standard.",
        author: "Sarah Johnson",
        location: "Ipswich"
      },
      {
        rating: 5,
        text: "Very responsive to our emergency call-out. Arrived quickly and resolved the issue efficiently.",
        author: "Mike Thompson",
        location: "Ipswich"
      }
    ]
  },
  woodbridge: {
    name: "Woodbridge",
    coordinates: {
      latitude: 52.0933,
      longitude: 1.3200
    },
    services: [
      {
        name: "Electrical Services",
        description: "Comprehensive electrical solutions for Woodbridge residents and businesses",
        features: [
          "Residential electrical work",
          "Commercial installations",
          "Emergency services",
          "Safety certificates",
          "Smart home solutions"
        ]
      },
      {
        name: "Air Conditioning",
        description: "Professional air conditioning services in Woodbridge",
        features: [
          "Home and office installations",
          "Maintenance contracts",
          "Repair services",
          "Energy-saving solutions",
          "Expert advice"
        ]
      }
    ],
    testimonials: [
      {
        rating: 5,
        text: "Great service at a competitive price. Would highly recommend for any electrical work.",
        author: "David Wilson",
        location: "Woodbridge"
      }
    ]
  },
  felixstowe: {
    name: "Felixstowe",
    coordinates: {
      latitude: 51.9617,
      longitude: 1.3511
    },
    services: [
      {
        name: "Electrical Services",
        description: "Reliable electrical services for Felixstowe properties",
        features: [
          "Domestic installations",
          "Commercial projects",
          "24/7 emergency service",
          "Safety inspections",
          "Modern upgrades"
        ]
      },
      {
        name: "Air Conditioning",
        description: "Expert air conditioning solutions in Felixstowe",
        features: [
          "Residential installations",
          "Business solutions",
          "Maintenance services",
          "Energy efficiency",
          "Professional advice"
        ]
      }
    ],
    testimonials: [
      {
        rating: 5,
        text: "Professional and reliable service. The team was very knowledgeable and helpful.",
        author: "Emma Brown",
        location: "Felixstowe"
      }
    ]
  },
  stowmarket: {
    name: "Stowmarket",
    coordinates: {
      latitude: 52.1889,
      longitude: 0.9972
    },
    services: [
      {
        name: "Electrical Services",
        description: "Quality electrical work for Stowmarket homes and businesses",
        features: [
          "Complete rewiring",
          "Commercial projects",
          "Emergency call-outs",
          "Safety certificates",
          "Modern solutions"
        ]
      },
      {
        name: "Air Conditioning",
        description: "Professional air conditioning services in Stowmarket",
        features: [
          "Home installations",
          "Business solutions",
          "Maintenance plans",
          "Repair services",
          "Expert guidance"
        ]
      }
    ],
    testimonials: [
      {
        rating: 5,
        text: "Excellent workmanship and very professional service. Would definitely use again.",
        author: "James Smith",
        location: "Stowmarket"
      }
    ]
  },
  buryStEdmunds: {
    name: "Bury St Edmunds",
    coordinates: {
      latitude: 52.2463,
      longitude: 0.7111
    },
    services: [
      {
        name: "Electrical Services",
        description: "Professional electrical services in Bury St Edmunds",
        features: [
          "Residential work",
          "Commercial projects",
          "Emergency services",
          "Safety inspections",
          "Modern upgrades"
        ]
      },
      {
        name: "Air Conditioning",
        description: "Expert air conditioning solutions in Bury St Edmunds",
        features: [
          "Home installations",
          "Business solutions",
          "Maintenance services",
          "Energy efficiency",
          "Professional advice"
        ]
      }
    ],
    testimonials: [
      {
        rating: 5,
        text: "Very satisfied with the service. The team was punctual and professional.",
        author: "Lisa Anderson",
        location: "Bury St Edmunds"
      }
    ]
  }
}; 