import React from 'react';
import './Reviews.css';

const Reviews = () => {
  const reviews = [
    {
      rating: 10,
      text: "Brunswick helped us get our MCB socket issue fixed very quickly. They were on time and very reliable and helpful. Highly recommended.",
      author: "Verified reviewer",
      service: "Fixed electric MCB socket",
      location: "IP3",
      date: "Today"
    },
    {
      rating: 10,
      text: "Simon installed 11 lights, 6 exterior, and upgraded our old garage circuit breaker to modern unit. All very professional.",
      author: "Verified reviewer",
      service: "Lighting installation, inside and out",
      location: "IP4",
      date: "2 days ago"
    },
    {
      rating: 10,
      text: "This is the second job Simon has done for us and we still can't fault him. He turned up when he said he would and he completed the job to our specification - even though we changed what we wanted only a couple of days before he",
      author: "Verified reviewer",
      service: "6 LED ceiling downlights and 6 double electrical sockets installed",
      date: "21 November 2024"
    }
  ];

  return (
    <section className="reviews-section">
      <div className="reviews-container">
        <div className="reviews-header">
          <div className="reviews-title">
            <h2>Trusted on Checkatrade</h2>
            <p className="reviews-subtitle">See what our customers say about us</p>
          </div>
          <div className="checkatrade-badge">
            <img 
              src="/images/accreditations/checkatrade.webp" 
              alt="Checkatrade Verified" 
              className="checkatrade-logo"
            />
            <div className="rating-summary">
              <div className="stars">★★★★★</div>
              <p>Average Rating: 10/10</p>
              <p className="review-count">Based on 111 reviews</p>
            </div>
          </div>
        </div>
        
        <div className="reviews-grid">
          {reviews.map((review, index) => (
            <div key={index} className="review-card">
              <div className="review-header">
                <div className="rating">{review.rating}/10</div>
                <span className="review-date">{review.date}</span>
              </div>
              <h3 className="review-service">{review.service}</h3>
              <p className="review-text">"{review.text}"</p>
              <div className="review-footer">
                <p className="review-author">{review.author}</p>
                {review.location && <p className="review-location">Location: {review.location}</p>}
              </div>
            </div>
          ))}
        </div>
        
        <a 
          href="https://www.checkatrade.com/trades/brunswickelectricalservicesltd" 
          target="_blank" 
          rel="noopener noreferrer" 
          className="view-more-button"
        >
          View All Reviews on Checkatrade
        </a>
      </div>
    </section>
  );
};

export default Reviews; 