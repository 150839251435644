import React from 'react';
import '../styles/Hero.css';

const Hero = () => {
  return (
    <section className="hero">
      <div className="hero-content">
        <h1>Professional Electrical & Air Conditioning Services</h1>
        <p>NICEIC Approved & F-Gas Registered - Serving Ipswich and Surrounding Areas</p>
        <div className="hero-buttons">
          <a href="/contact" className="primary-button">Book a Service</a>
          <a href="/services" className="secondary-button">Our Services</a>
        </div>
      </div>
      <div className="hero-features">
        <div className="feature">
          <span className="icon">✓</span>
          <span>25+ Years Experience</span>
        </div>
        <div className="feature">
          <span className="icon">✓</span>
          <span>NICEIC Approved</span>
        </div>
        <div className="feature">
          <span className="icon">✓</span>
          <span>F-Gas Registered</span>
        </div>
      </div>
    </section>
  );
};

export default Hero; 